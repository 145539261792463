import PropTypes from 'prop-types'
import Icon from 'common/components/Icon'
import styled from '@emotion/styled'
import { Oval } from 'react-loader-spinner'
import Flex from './Flex'

const buttonStyles = {
    default: `
    color: var(--color-white);
    background: var(--color-black);
    :hover {
        box-shadow: -1px 18px 20px -13px var(--color-black-one-third-opacity);
    }
    :disabled {
        pointer-events: none;
        cursor: default;
        background: var(--color-ice-white);
        border: 1px solid;
        border-color: var(--color-medium-dark-gray);
        color: var(--color-dark-gray);
    }
`,
    outline: `
    background: var(--color-white);
    color: var(--color-black);
    border: 1px solid var(--color-black);
    :hover {
        box-shadow: -1px 18px 20px -13px var(--color-black-one-third-opacity);
    }
    :disabled {
        pointer-events: none;
        cursor: default;
        background: var(--color-ice-white);
        border: 1px solid;
        border-color: var(--color-medium-dark-gray);
        color: var(--color-dark-gray);
    }
`,
    invert: `
    background: var(--color-snow-white);
    color: var(--color-black);
    :hover {
        box-shadow: -1px 18px 20px -13px var(--color-black-one-third-opacity);
    }
    :disabled {
        pointer-events: none;
        cursor: default;
        background: var(--color-ice-white);
        border: 1px solid;
        border-color: var(--color-medium-dark-gray);
        color: var(--color-dark-gray);
    }
`,
    tertiary: `
    background: none;
    color: var(--color-black);
    text-decoration: underline;
    :hover {
        color: var(--color-medium-dark-gray);
    }
    :disabled {
        pointer-events: none;
        cursor: default;
        color: var(--color-dark-gray);
    }
`,
}

const largeCommon = `
font-size: 16px;
line-height: 16px;
min-height: 16px;
padding: 15px 0;
`

const sizeStyles = {
    full: `
    width: 100%;
    ${largeCommon}
`,
    'x-large': `
    width: 340px;
    ${largeCommon}
`,
    large: `
    width: 260px;
    ${largeCommon}
 `,
    medium: `
    width: 190px;
    ${largeCommon}
 `,
    small: `
    width: 190px;
    font-size: 12px;
    padding: 12px 0;
 `,
    none: '',
}

const StyledButton = styled.button`
    all: unset;
    font-weight: 700;
    text-align: center;
    cursor: pointer;
    border-radius: 6px;
    transition: all 0.2s ease;
    letter-spacing: 0.5px;
    min-height: 14px;
    :disabled {
        pointer-events: none;
        cursor: default;
    }
    ${({ variant }) => buttonStyles[variant]};
    ${({ size }) => sizeStyles[size]}
`

const StyledLoader = styled(Oval)`
    left: 50%;
`

const Button = ({
    variant = 'default',
    size = 'none',
    icon,
    loading,
    disabled = false,
    children,
    ...rest
}) =>
    variant === 'iconOnly' ? (
        <StyledButton variant={variant} size={size} disabled={loading ? true : disabled} {...rest}>
            {loading ? (
                <Flex centered>
                    <StyledLoader height={16} width={16} />
                </Flex>
            ) : (
                <Icon name={icon} />
            )}
        </StyledButton>
    ) : (
        <StyledButton variant={variant} size={size} disabled={loading ? true : disabled} {...rest}>
            {loading ? (
                <Flex centered>
                    <StyledLoader height={16} width={16} />
                </Flex>
            ) : (
                children
            )}
        </StyledButton>
    )

Button.propTypes = {
    children: PropTypes.node,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    variant: PropTypes.string,
    icon: PropTypes.string,
    size: PropTypes.string,
}

export default Button
