import bgImage from 'assets/png/bg-image.png'
import { Global, css, useTheme } from '@emotion/react'

const GlobalStyles = () => {
    const theme = useTheme()
    return (
        <Global
            styles={css`
                html {
                    box-sizing: border-box;
                    height: 100%;
                    font-family: proxima-nova, sans-serif;
                    color: ${theme.colors.black};
                    background: ${theme.colors.snowWhite} url(${bgImage});
                    background-attachment: fixed;
                    background-size: cover;
                    --color-black: ${theme.colors.black};
                    --color-black-semi-opaque: ${theme.colors.blackSemiOpaque};
                    --color-black-one-third-opacity: ${theme.colors.blackOneThirdOpacity};
                    --color-black-one-quarter-opacity: ${theme.colors.blackOneQuarterOpacity};
                    --color-black-low-opacity: ${theme.colors.blackLowOpacity};
                    --color-dark-gray: ${theme.colors.darkGray};
                    --color-medium-dark-gray: ${theme.colors.mediumDarkGray};
                    --color-medium-light-gray: ${theme.colors.mediumLightGray};
                    --color-light-gray: ${theme.colors.lightGray};
                    --color-ice-white: ${theme.colors.iceWhite};
                    --color-white: ${theme.colors.white};
                    --color-snow-white: ${theme.colors.snowWhite};
                    --color-background: ${theme.colors.snowWhite};
                    --color-background-light: ${theme.colors.white};
                    --color-green: ${theme.colors.green};
                    --color-light-green: ${theme.colors.lightGreen};
                    --color-light-pastel-green: ${theme.colors.lightPastelGreen};
                    --color-pastel-green: ${theme.colors.pastelGreen};
                    --color-background-green: ${theme.colors.backgroundGreen};
                    --color-blue: ${theme.colors.blue};
                    --color-light-blue: ${theme.colors.lightBlue};
                    --color-pastel-blue: ${theme.colors.pastelBlue};
                    --color-light-pastel-blue: ${theme.colors.lightPastelBlue};
                    --color-background-blue: ${theme.colors.backgroundBlue};
                    --color-light-background-blue: ${theme.colors.lightBackgroundBlue};
                    --color-red: ${theme.colors.red};
                    --color-light-red: ${theme.colors.lightRed};
                    --color-purple: ${theme.colors.purple};
                    --color-light-purple: ${theme.colors.lightPurple};
                    --color-light-yellow: ${theme.colors.lightYellow};
                }
                body,
                #root {
                    margin: 0;
                    height: 100%;
                }
                button {
                    font-family: proxima-nova, sans-serif;
                }
                *,
                *:before,
                *:after {
                    box-sizing: border-box;
                }
            `}
        />
    )
}

export default GlobalStyles
