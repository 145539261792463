import React from 'react'
import { createRoot } from 'react-dom/client'
import 'analytics'
import { QueryClientProvider } from '@tanstack/react-query'
import { HelmetProvider } from 'react-helmet-async'
import * as Sentry from '@sentry/browser'
import reportWebVitals from 'reportWebVitals'
import { bootIntercom } from 'support'
import Router from 'routing/Router'
import queryClient from 'api/query'
import ConnectedThemeProvider from 'styling/components/ConnectedThemeProvider'
import ErrorBoundary from 'common/components/ErrorBoundary'
import Flex from 'common/components/Flex'
import Loader from 'common/components/Loader'

if (
    process.env.REACT_APP_TRUST_ENV === 'production' ||
    process.env.REACT_APP_TRUST_ENV === 'staging'
) {
    Sentry.init({
        dsn: 'https://5a8d783565054ab69cfc6286edfbfc5c@sentry.io/1861360',
        environment: process.env.REACT_APP_TRUST_ENV,
    })
}

const container = document.getElementById('root')
const root = createRoot(container) // createRoot(container!) if you use TypeScript
root.render(
    <React.StrictMode>
        <ConnectedThemeProvider>
            <QueryClientProvider client={queryClient}>
                <HelmetProvider>
                    <ErrorBoundary>
                        <React.Suspense
                            fallback={
                                <Flex p="48px" centered>
                                    <Loader />
                                </Flex>
                            }
                        >
                            <Router />
                        </React.Suspense>
                    </ErrorBoundary>
                </HelmetProvider>
            </QueryClientProvider>
        </ConnectedThemeProvider>
    </React.StrictMode>
)

bootIntercom()
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
