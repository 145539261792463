import { TOKEN_STORAGE_KEY } from 'common/constants'
import { errorToast } from 'common/toasts'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import queryClient from 'api/query'
import { verifyJWT } from 'user/api'
import { useQuery } from '@tanstack/react-query'
import OnboardingTile from 'common/components/OnboardingTile'
import Loader from 'common/components/Loader'
import { useCallback, useEffect } from 'react'

const VerifyUser = () => {
    const { token } = useParams()
    const navigate = useNavigate()
    const [search] = useSearchParams()
    const verifyToken = useCallback(() => verifyJWT(token), [token])
    const { isSuccess } = useQuery({
        queryKey: ['verifyUser'],
        queryFn: verifyToken,
        onSuccess: (res) => {
            const { access_token } = res.data
            localStorage.setItem(TOKEN_STORAGE_KEY, access_token)
            queryClient.setQueryData(['paymentRequestId'], search.get('prid'))
            localStorage.setItem('OnboardingPRID', search.get('prid'))
        },
        onError: () => {
            errorToast('There was an error when verifying user.')
        },
    })
    useEffect(() => {
        if (isSuccess) {
            queryClient.invalidateQueries({ queryKey: ['currentUser'] })
            navigate('../../onboarding/')
        }
    }, [isSuccess, navigate])
    return (
        <OnboardingTile title="Verifying...">
            <Loader />
        </OnboardingTile>
    )
}

export default VerifyUser
