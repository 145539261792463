import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { errorToast } from 'common/toasts'
import { useQuery } from '@tanstack/react-query'
import { getMiddeskVerification } from 'organizations/api'
import ProcessingScreen from 'common/components/ProcessingScreen'

const UnderwritingCheck = () => {
    const navigate = useNavigate()
    const { paymentRequestId } = useParams()
    const [intervalCount, setIntervalCount] = useState(0)
    const [intervalMs, setIntervalMs] = useState(1000)
    const checkUnderwriting = useCallback(
        () => getMiddeskVerification(paymentRequestId),
        [paymentRequestId]
    )
    const handleError = useCallback(() => {
        setIntervalMs(false)
        errorToast(
            'There was an error when checking for underwriting status. Please try again later.'
        )
    }, [setIntervalMs])
    const { isSuccess, data } = useQuery({
        queryKey: ['fetchMiddeskVerification'],
        queryFn: checkUnderwriting,
        onError: handleError,
        refetchInterval: intervalMs,
    })
    useEffect(() => {
        if (isSuccess) {
            const { underwriting_check } = data.data
            switch (underwriting_check) {
                case true:
                    setIntervalMs(false)
                    break
                case false:
                    setIntervalMs(false)
                    break
                default:
                    setIntervalCount((curr) => curr + 1)
                    break
            }
        }
    }, [isSuccess, data, setIntervalCount, setIntervalMs])

    useEffect(() => {
        if (intervalCount === 100) {
            setIntervalMs(false)
            errorToast(
                'There was an error when checking for underwriting status. Please try again later.'
            )
            // temp redirect for testing purposes
            navigate('../internal-error')
        }
    }, [intervalCount, setIntervalMs, navigate])
    return <ProcessingScreen />
}

export default UnderwritingCheck
