import { Toaster } from 'react-hot-toast'
// import { chartColors } from './charting/util'

const CustomToaster = () => (
    <Toaster
        position="top-right"
        toastOptions={{
            containerStyle: {
                justifyContent: 'flex-start',
            },
            style: {
                justifyContent: 'flex-start',
                minWidth: '300px',
                position: 'relative',
                background: 'var(--color-light-gray)',
                padding: '18px 20px',
                color: 'var(--color-black)',
                boxShadow: '0px 4px 4px 0px #00000040',
                border: '1px solid #e0e0e0',
            },
            error: {
                style: {
                    background:
                        'linear-gradient(140deg, rgb(238, 205, 208) 0%, var(--color-snow-white) 30%)',
                },
                icon: (
                    <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle cx="15" cy="15" r="15" fill="#FF5E5E" />
                        <path
                            d="M11.7071 10.2929C11.3166 9.90237 10.6834 9.90237 10.2929 10.2929C9.90237 10.6834 9.90237 11.3166 10.2929 11.7071L11.7071 10.2929ZM18.2929 19.7071C18.6834 20.0976 19.3166 20.0976 19.7071 19.7071C20.0976 19.3166 20.0976 18.6834 19.7071 18.2929L18.2929 19.7071ZM10.2929 11.7071L18.2929 19.7071L19.7071 18.2929L11.7071 10.2929L10.2929 11.7071Z"
                            fill="white"
                        />
                        <path
                            d="M18.2929 10.2929C18.6834 9.90237 19.3166 9.90237 19.7071 10.2929C20.0976 10.6834 20.0976 11.3166 19.7071 11.7071L18.2929 10.2929ZM11.7071 19.7071C11.3166 20.0976 10.6834 20.0976 10.2929 19.7071C9.90237 19.3166 9.90237 18.6834 10.2929 18.2929L11.7071 19.7071ZM19.7071 11.7071L11.7071 19.7071L10.2929 18.2929L18.2929 10.2929L19.7071 11.7071Z"
                            fill="white"
                        />
                    </svg>
                ),
            },
            success: {
                style: {
                    background:
                        'linear-gradient(140deg, rgb(211, 233, 223) 0%, var(--color-snow-white) 30%)',
                },
                icon: (
                    <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle cx="15" cy="15" r="15" fill="#1FA866" />
                        <path
                            d="M10 15.9248L13.4624 19.3873L20.3873 12.4624"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                ),
            },
        }}
    />
)

export default CustomToaster
