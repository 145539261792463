import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { useState } from 'react'
import Flex from './Flex'
import Icon from './Icon'
import TextInput from './TextInput'

const TextInputWrapper = styled(Flex)`
    position: relative;
`

const VisibilityIcon = styled(Icon)`
    transition: opacity 0.3s ease;
    position: absolute;
    width: 20px;
    right: 10px;
    opacity: 0.5;
    cursor: pointer;
    transition: all 0.3s ease;
    :hover {
        opacity: 1;
    }
`

const PasswordInput = ({ toggleVisibility, ...rest }) => {
    const [passwordVisible, setPasswordVisible] = useState(false)
    return (
        <TextInputWrapper centerAlign>
            <TextInput type={toggleVisibility && passwordVisible ? 'text' : 'password'} {...rest} />
            {toggleVisibility && (
                <VisibilityIcon
                    name={passwordVisible ? 'invisible' : 'visible'}
                    onClick={() => setPasswordVisible(!passwordVisible)}
                />
            )}
        </TextInputWrapper>
    )
}

PasswordInput.propTypes = {
    toggleVisibility: PropTypes.bool,
}

export default PasswordInput
