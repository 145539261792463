import { useCallback } from 'react'
import { isEmail } from 'validator'
import { useMutation } from '@tanstack/react-query'
import styled from '@emotion/styled'
import TextInput from 'common/components/TextInput'
import Button from 'common/components/Button'
import SinglePageCard from 'common/components/SinglePageCard'
import Link from 'common/components/Link'
import Flex from 'common/components/Flex'
import { useForm } from 'react-hook-form'
import { UI_ROUTES } from 'routes'
import { authUser } from 'user/api'
import { errorToast } from 'common/toasts'
import { TOKEN_STORAGE_KEY } from 'common/constants'
import queryClient from 'api/query'

const SpacedLink = styled(Link)`
    margin-bottom: 22px;
`

const Login = () => {
    const { isLoading, mutate } = useMutation({
        mutationFn: authUser,
        onSuccess: ({ data }) => {
            localStorage.setItem(TOKEN_STORAGE_KEY, data.access_token)
            queryClient.invalidateQueries({ queryKey: ['currentUser'] })
        },
        onError: (error) => errorToast(error.detail),
    })
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm()

    const onSubmit = useCallback((values) => mutate(values), [mutate])

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <SinglePageCard
                logo
                cardLogo
                title="Sign in"
                subHead="Use your email and password to log in."
            >
                <TextInput
                    errors={errors}
                    inputRef={() =>
                        register('email', {
                            required: true,
                            validate: (value) =>
                                !isEmail(value) ? 'Please enter a valid email address.' : true,
                        })
                    }
                    showLabel
                    label="Email"
                    name="email"
                />
                <TextInput
                    errors={errors}
                    inputRef={() => register('password', { required: true })}
                    showLabel
                    name="password"
                    type="password"
                    label="Password"
                />
                <SpacedLink variant="primaryColor" internal to={`../${UI_ROUTES.REQUEST_RESET}`}>
                    Forgot email or password?
                </SpacedLink>
                <Flex centered>
                    <Button loading={isLoading} type="submit" size="medium">
                        Login
                    </Button>
                </Flex>
            </SinglePageCard>
        </form>
    )
}

export default Login
