import PropTypes from 'prop-types'
import { ThemeProvider } from '@emotion/react'
import themes from '../themes'
import GlobalStyles from './GlobalStyles'

const ConnectedThemeProvider = ({ children }) => {
    return (
        <ThemeProvider theme={themes.light}>
            <GlobalStyles />
            {children}
        </ThemeProvider>
    )
}

ConnectedThemeProvider.propTypes = {
    children: PropTypes.node,
}

export default ConnectedThemeProvider
