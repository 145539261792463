import { invert } from 'lodash'

export const COMMENT_STATUS = {
    ACTIVE: 1,
    DELETED: 2,
    PENDING: 3,
    FLAGGED: 4,
}

export const TX_STATUS = {
    pending: 0,
    succeeded: 1,
    failed: 2,
    closed: 3,
    reversed: 4,
}

export const GENERIC_STATUS = {
    ACTIVE: 1,
    DISABLED: 2,
    PENDING: 3,
}

export const INTEGRATION_STATUS = {
    INTERNAL_ERROR: 0,
    REJECTED: 1,
    SUCCESS: 2,
    REVOKED: 3,
    PENDING: 4,
    DUPLICATE: 5,
}

export const TX_TYPE = {
    CREDIT: 0,
    DEBIT: 1,
}

export const ROLE_TYPE = {
    ADMIN: 0,
    CARD_HOLDER: 1,
    ANALYST: 2,
    ACCOUNTING: 3,
}

export const ROLE_TYPE_INVERT = invert(ROLE_TYPE)

export const CARD_STATUS = {
    PENDING: 0,
    ACTIVE: 1,
    INACTIVE: 2,
    DENIED: 3,
    TERMINATED: 4,
}

export const CARD_LIMIT_TYPES = {
    MONTHLY: 0,
    LIFETIME: 1,
    NONE: 2,
}

export const CARD_LIMIT_TYPE_INVERT = invert(CARD_LIMIT_TYPES)

export const PAYMENT_METHOD_STATUS = {
    UNVERIFIED: 0,
    VERIFIED: 1,
    ERROR: 2,
}

export const CREDIT_TERM_STATUS = {
    OPEN: 0,
    APPROVED: 1,
    DENIED: 2,
    REVOKED: 3,
}

export const GROUP_TYPE = {
    BUSINESS: 0,
    TOPIC: 1,
}

export const PAYMENT_REQUEST_STATUS = {
    PENDING: 0,
    APPROVED: 1,
    DENIED: 2,
}

export const PAYMENT_REQUEST_BUYER_STATUS = {
    PENDING: 0,
    IN_PROGRESS: 1,
    AWAITING_ACH: 2,
    COMPLETED: 3,
}

export const PAYMENT_AGREEMENT_STATUS = {
    RECEIVED: 1,
    PENDING_AUTHORIZATION: 2,
    PENDING_AUTHENTICATION: 3,
    AUTHENTICATION_REDIRECTED: 4,
    PENDING_MANUAL_REVIEW: 5,
    AUTHORIZED: 6,
    CANCELLED: 7,
    EXPIRED: 8,
    CAPTURE_REQUESTED: 9,
    SETTLED: 10,
    PAID: 11,
    RISK_DECLINED: 12,
    AUTHORIZATION_FAILED: 13,
    CAPTURE_FAILED: 14,
    FAILED_TO_PROCESS: 15,
}
