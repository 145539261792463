import callApi from 'api/callApi'
import { USER_ROUTES } from 'api/endpoints'

export const authUser = (userInfo) =>
    callApi(USER_ROUTES.ACCESS_TOKEN, 'post', {
        data: userInfo,
    })

export const postNewUser = (userInfo) =>
    callApi(USER_ROUTES.SIGN_UP, 'post', {
        data: userInfo,
    })

export const fetchCurrentUser = () => callApi(USER_ROUTES.ME)

export const patchCurrentUser = (newValues) => callApi(USER_ROUTES.ME, 'patch', { data: newValues })

export const postProfilePicture = (picture) =>
    callApi(USER_ROUTES.PROFILE_PIC, 'post', {
        additionalHeaders: {
            'Content-Type': 'multipart/form-data',
        },
        data: picture,
    })

export const verifyJWT = (token) => callApi(USER_ROUTES.VERIFY_TOKEN(token))

export const requestPasswordReset = (email) => callApi(USER_ROUTES.REQUEST_RESET(email))
