/* eslint-disable import/prefer-default-export */
export const bootIntercom = (user = null) => {
    user === null
        ? window.Intercom('boot', {
              app_id: 'aq102wh5',
          })
        : window.Intercom('boot', {
              app_id: 'aq102wh5',
              email: user.email,
              user_id: user.id,
          })
}
