import PropTypes from 'prop-types'
// import { motion } from 'framer-motion'
import styled from '@emotion/styled'
import { NavLink } from 'react-router-dom'
import Text from './Text'

const MainNavItem = styled(NavLink)`
    display: flex;
    padding: 20px;
    position: relative;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    font-size: 24px;
    background: transparent;
    color: var(--color-black);
    letter-spacing: 1px;
    &:hover {
        background: var(--color-snow-white);
    }
    &.active {
        background: var(--color-snow-white);
    }
`

// const SubNavWrapper = styled(motion.div)`
//     display: flex;
//     flex-direction: column;
//     overflow: hidden;
// `

// const SubNavItem = styled(NavLink)`
//     padding: 10px 20px;
//     margin: 10px 0 10px 20px;
//     border-radius: 8px 0 0 8px;
//     text-decoration: none;
//     color: var(--color-black);
//     font-weight: 700;
//     font-size: 14px;
//     letter-spacing: 1px;
//     &:hover {
//         background-color: ${({ color }) => color};
//     }
// `

const SidebarIcon = styled.img`
    width: 22px;
    height: 22px;
    object-fit: contain;
    margin-left: 9px;
`

const NavItemText = styled(Text)`
    margin-left: 10px;
`

// const ArrowIcon = styled.img`
//     width: 20px;
//     height: 20px;
//     position: absolute;
//     right: 10px;
//     transform: rotate(${({ selected }) => (selected ? '270' : '180')}deg);
//     transition: transform 0.3s ease;
// `

const NavItem = ({ navItem, setSelected, expanded }) => {
    const { path, icon, label } = navItem
    return (
        <>
            <MainNavItem to={path} onClick={setSelected}>
                <SidebarIcon src={icon} alt={label} expanded={expanded} />
                {expanded && (
                    <>
                        <NavItemText variant="p2" bold>
                            {label}
                        </NavItemText>
                        {/* {SUB_ITEMS && (
                            <ArrowIcon src={downChevron} alt="Chevron arrow" selected={selected} />
                        )} */}
                    </>
                )}
            </MainNavItem>
            {/* {SUB_ITEMS && (
                <AnimatePresence>
                    {selected &&
                        SUB_ITEMS.map(({ PATH, TITLE: SUB_NAV_TITLE }) => (
                            <SubNavWrapper
                                key={PATH}
                                initial={{ opacity: 0, height: '0px' }}
                                animate={{
                                    opacity: 1,
                                    height: 'fit-content',
                                }}
                                exit={{ opacity: 0, height: '0px' }}
                            >
                                <SubNavItem key={PATH} to={`${LINK}${PATH}`} color={SUB_NAV_COLOR}>
                                    {SUB_NAV_TITLE}
                                </SubNavItem>
                            </SubNavWrapper>
                        ))}
                </AnimatePresence>
            )} */}
        </>
    )
}

NavItem.propTypes = {
    navItem: PropTypes.object,
    setSelected: PropTypes.func,
    expanded: PropTypes.bool,
}

export default NavItem
