import styled from '@emotion/styled'
import { useQuery } from '@tanstack/react-query'
import redCircleX from 'assets/svg/icons/redCircleX.svg'
import Button from 'common/components/Button'
import Flex from 'common/components/Flex'
import Loader from 'common/components/Loader'
import Text from 'common/components/Text'
import Tile from 'common/components/Tile'
import { fetchPaymentAgreements } from 'paymentAgreements/api'
import { useNavigate } from 'react-router-dom'

const RejectedIcon = styled.img`
    width: 60px;
    height: 60px;
    object-fit: contain;
    margin-bottom: 20px;
`

const Message = styled(Text)`
    width: 400px;
    line-height: 24px;
    margin: 20px 0;
`

const ContactButton = styled.a`
    font-size: 16px;
    line-height: 16px;
    min-height: 16px;
    padding: 15px 0;
    width: 190px;
    text-decoration: none;
    background-color: var(--color-black);
    color: white;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    border-radius: 12px;
    transition: all 0.2s ease;
    letter-spacing: 0.5px;
    :hover {
        box-shadow: -1px 18px 20px -13px var(--color-black-one-third-opacity);
    }
`

const ApplicationDenied = () => {
    const navigate = useNavigate()
    const { isLoading, data } = useQuery({
        queryKey: ['fetchPaymentAgreements'],
        queryFn: fetchPaymentAgreements,
    })
    if (isLoading) return <Loader />
    return (
        <Tile>
            <RejectedIcon src={redCircleX} alt="Rejected icon" />
            <Text variant="h1">Your application has been denied</Text>
            <Message variant="p1" centered>
                We apologize, your loan application has been denied. If you believe the rejected
                application is a mistake or want to learn about other options, please contact us.
            </Message>
            <Flex gap="10" centered>
                <ContactButton href="mailto:support@getmarket.com" target="_blank">
                    Contact us
                </ContactButton>
                {!!data?.data?.length && (
                    <Button size="medium" onClick={() => navigate('../../../payments')}>
                        Go to payments
                    </Button>
                )}
            </Flex>
        </Tile>
    )
}

export default ApplicationDenied
