import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import Card from './Card'
import Flex from './Flex'
import Text from './Text'

const PageSectionCard = styled(Card)`
    overflow: hidden;
`

const PageSectionIcon = styled.img`
    width: 30px;
    height: 30px;
    object-fit: contain;
`

const PageSection = ({ icon, title, children, controls, tableSection = false }) => {
    return (
        <PageSectionCard noPadding={tableSection}>
            {title && (
                <Flex justify="space-between" p={tableSection ? '20px 20px 10px' : '0'} centerAlign>
                    <Flex gap="10" m="0 0 10px" centerAlign>
                        {icon && <PageSectionIcon src={icon} alt={`${title} section icon`} />}
                        <Text variant="h2">{title}</Text>
                    </Flex>
                    {controls && <Flex centerAlign>{controls}</Flex>}
                </Flex>
            )}
            {children}
        </PageSectionCard>
    )
}

PageSection.propTypes = {
    icon: PropTypes.string,
    title: PropTypes.string,
    children: PropTypes.node,
    controls: PropTypes.node,
    tableSection: PropTypes.bool,
}

export default PageSection
