import React from 'react'
import Layout from 'common/components/Layout'
import { Outlet, useLocation } from 'react-router-dom'
import ReactModal from 'react-modal'
import 'common/util'
import { useQuery } from '@tanstack/react-query'
import { fetchCurrentUser } from 'user/api'
import { fetchPaymentAgreements } from 'paymentAgreements/api'

ReactModal.setAppElement('#root')

// const publicRoutes = [
//     {
//         key: 'login',
//         path: UI_ROUTES.LOGIN,
//         component: <Login />,
//     },
//     {
//         key: 'verifyUser',
//         path: UI_ROUTES.VERIFY_USER,
//         component: <VerifyUser />,
//     },
//     {
//         key: 'requestReset',
//         path: UI_ROUTES.REQUEST_RESET,
//         component: <PasswordResetRequest />,
//     },
//     {
//         key: 'resetPassword',
//         path: UI_ROUTES.RESET_PASSWORD,
//         component: <PasswordReset />,
//     },
// ]

const App = () => {
    const { pathname } = useLocation()
    const { isInitialLoading, data } = useQuery({
        queryKey: ['currentUser'],
        queryFn: fetchCurrentUser,
        enabled: !pathname.includes('/verify-user'),
        useErrorBoundary: false,
        suspense: true,
    })
    const {
        isSuccess,
        data: paymentAgreementData,
        isLoading,
    } = useQuery({
        queryKey: ['fetchPaymentAgreements'],
        queryFn: fetchPaymentAgreements,
        enabled: !!data?.data?.organization?.organization_buyer,
        suspense: true,
    })
    return (
        <Layout
            isLoading={isInitialLoading || isLoading}
            showSidebar={isSuccess && !!paymentAgreementData?.data?.length}
        >
            <Outlet />
        </Layout>
    )
}

export default App
