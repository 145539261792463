import { useState, useCallback, useEffect } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { errorToast, successToast } from 'common/toasts'
import { useQuery } from '@tanstack/react-query'
import { reinviteBuyer } from 'organizations/api'
import Button from './Button'

const StatusButton = styled(Button)`
    :disabled {
        color: black;
        border-color: transparent;
        background-color: ${({ status }) =>
            status === 'error' ? 'var(--color-light-red)' : 'var(--color-pastel-blue)'};
    }
`

const ReinviteButton = ({ buyerId, view }) => {
    const [inviteStatus, setInviteStatus] = useState('waiting')
    const reinviteBuyerById = useCallback(() => reinviteBuyer(buyerId), [buyerId])
    const { isInitialLoading, isSuccess, isError, data } = useQuery({
        queryKey: [`reinviteUser${buyerId}`],
        queryFn: reinviteBuyerById,
        enabled: !!(inviteStatus === 'clicked'),
        suspense: false,
    })
    useEffect(() => {
        if (isSuccess) {
            setInviteStatus('success')
            successToast(data.data)
        }
        if (isError) {
            setInviteStatus('error')
            errorToast()
        }
    }, [isSuccess, setInviteStatus, isError, data])
    return (
        <StatusButton
            status={inviteStatus}
            size={view === 'payment' ? 'full' : 'small'}
            loading={isInitialLoading}
            disabled={inviteStatus === 'success' || inviteStatus === 'error'}
            onClick={() => setInviteStatus('clicked')}
        >
            {inviteStatus === 'waiting' && 'Resend Invite'}
            {inviteStatus === 'success' &&
                (view === 'payment' ? 'Your invite has been resent.' : 'Invite resent.')}
            {inviteStatus === 'error' && 'Unable to re-invite.'}
        </StatusButton>
    )
}

ReinviteButton.propTypes = {
    buyerId: PropTypes.string,
    view: PropTypes.string,
}

export default ReinviteButton
