import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { useState } from 'react'
import {
    useReactTable,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
} from '@tanstack/react-table'
import Icon from 'common/components/Icon'
import Flex from 'common/components/Flex'

const Styles = styled.div`
    table {
        width: 100%;
        border-spacing: 0;
        border: 0px transparent;
        th,
        td {
            margin: 0;
            border-right: none;
            font-weight: 200;
            padding: 20px 40px;
            font-size: 14px;
            text-align: center;
            :first-of-type {
                text-align: left;
            }
            :last-of-type {
                text-align: right;
            }
        }

        th {
            font-size: 15px;
            font-weight: 700;
            padding: 20px 40px 10px;
            position: relative;
            white-space: nowrap;
            border-bottom: 2px solid var(--color-medium-light-gray);
            ${({ clickable }) =>
                clickable &&
                `
                    :last-of-type {
                        width: 50px;
                        padding: 10px;
                    }
                `}
            :last-of-type {
                ::after {
                    background: transparent;
                }
            }
        }
        .header-wrap {
            display: flex;
            align-items: center;
        }
        .hide-pipe {
            ::after {
                background: transparent;
            }
        }
    }
`

const ClickableRow = styled.tr`
    border-bottom: 2px solid var(--color-black);
    background-color: transparent;
    td {
        border-bottom: 1px solid var(--color-medium-light-gray);
        padding: 20px 0;
        ${({ clickable }) =>
            clickable &&
            `
                :last-of-type {
                    padding: 20px;
                }
            `}
    }
    :last-of-type {
        td {
            border-color: transparent;
        }
    }
    ${({ clickable }) =>
        clickable &&
        `
            cursor: pointer;
            transition: all 0.3s ease;
            :hover {
                background-color: var(--color-background-green);
            }
        `}
`

const Arrow = styled(Icon)`
    margin-left: 4px;
    width: 12px;
    height: 12px;
`
const UpArrow = styled(Arrow)`
    transform: rotateX(180deg);
`

const getHeaderJustification = (index, length) => {
    if (index === 0) {
        return 'flex-start'
    }

    if (index === length - 1) {
        return 'flex-end'
    }

    return 'center'
}

const Table = ({ columns, data, onRowClick = null, initialSort }) => {
    const [sorting, setSortingState] = useState(initialSort ? [initialSort] : [])
    const { getHeaderGroups, getRowModel } = useReactTable({
        columns,
        data,
        enableSortingRemoval: false,
        state: {
            sorting,
        },
        onSortingChange: setSortingState,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    })
    return (
        <Styles clickable={!!onRowClick}>
            <table>
                <thead>
                    {getHeaderGroups().map((headerGroup) => {
                        return (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <th key={header.id}>
                                        <div
                                            {...(header.column.getCanSort()
                                                ? {
                                                      style: {
                                                          cursor: 'pointer',
                                                      },
                                                      onClick:
                                                          header.column.getToggleSortingHandler(),
                                                  }
                                                : {})}
                                        >
                                            <Flex
                                                centerAlign
                                                justify={getHeaderJustification(
                                                    header.index,
                                                    headerGroup.headers.length
                                                )}
                                            >
                                                {flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )}
                                                {{
                                                    asc: <UpArrow name="downarrow" />,
                                                    desc: <Arrow name="downarrow" />,
                                                }[header.column.getIsSorted()] ?? null}
                                            </Flex>
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        )
                    })}
                </thead>
                <tbody>
                    {getRowModel().rows.map((row) => (
                        <ClickableRow
                            key={row.id}
                            clickable={!!onRowClick}
                            onClick={onRowClick ? () => onRowClick(row.original.id) : null}
                        >
                            {row.getVisibleCells().map((cell) => (
                                <td key={cell.id}>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                            ))}
                        </ClickableRow>
                    ))}
                </tbody>
            </table>
        </Styles>
    )
}

Table.propTypes = {
    data: PropTypes.array,
    columns: PropTypes.array,
    onRowClick: PropTypes.func,
    initialSort: PropTypes.shape({
        id: PropTypes.string.isRequired,
        desc: PropTypes.bool.isRequired,
    }),
}

export default Table
