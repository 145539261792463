import styled from '@emotion/styled'
import { useQuery } from '@tanstack/react-query'
import Flex from 'common/components/Flex'
import Loader from 'common/components/Loader'
import SidebarModal from 'common/components/SidebarModal'
import ProgressBar from 'common/components/ProgressBar'
import Text from 'common/components/Text'
import { paymentRequestStatusMap } from 'common/statusMaps'
import { formatCurrency } from 'common/util'
import dayjs from 'dayjs'
import { getAllPaymentRequests } from 'paymentRequests/api'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import StatusCell from 'common/table/StatusCell'
import { fetchCurrentUser } from 'user/api'
import Tooltip from 'common/components/Tooltip'
import InvoiceDetailsButton from './InvoiceDetailsButton'

const PaymentDetailRow = styled(Flex)`
    padding: 20px 0;
    align-items: center;
    &:not(:last-of-type) {
        border-bottom: 1px solid var(--color-light-gray);
    }
    & > div:last-of-type {
        max-width: 400px;
        text-align: right;
    }
`

const DetailValueText = styled(Text)`
    font-size: 18px;
`

const VendorRequestDetailsModal = ({ ...rest }) => {
    const { paymentRequestId } = useParams()
    const { data: paymentRequestData, isLoading: requestsFetching } = useQuery({
        queryKey: ['listPaymentRequests'],
        queryFn: getAllPaymentRequests,
        enabled: false,
    })
    const { data, isLoading } = useQuery({
        queryKey: ['currentUser'],
        queryFn: fetchCurrentUser,
        enabled: false,
    })
    const {
        payment_amount,
        vendor_final_payment_amount,
        vendor_fee_percentage,
        invoice_date,
        payment_due_date,
        memo,
        payment_request_status,
        buyer,
        created_at,
    } = useMemo(
        () => paymentRequestData?.data.find((pr) => pr.id === paymentRequestId) ?? {},
        [paymentRequestData, paymentRequestId]
    )
    const statusValue = useMemo(() => {
        if (payment_request_status?.length > 1) {
            const finalValue =
                paymentRequestStatusMap[payment_request_status?.at(-1)?.status]?.value
            return finalValue === paymentRequestStatusMap['Payment Error'].value
                ? paymentRequestStatusMap[payment_request_status?.at(-2)?.status]?.value
                : finalValue
        }
        if (dayjs().isBefore(invoice_date)) return 0
        return 1
    }, [payment_request_status, invoice_date])
    const progressMapValues = useMemo(() => {
        const statusKeys = Object.keys(paymentRequestStatusMap)
        const statusMapValues = [
            { text: 'Scheduled', subtext: dayjs(created_at).format('MM/DD/YYYY') },
        ]
        statusKeys.forEach((k) => {
            const foundKey = payment_request_status?.find(({ status }) => status === k)
            statusMapValues.push({
                text: paymentRequestStatusMap[k]?.text,
                subtext: foundKey ? dayjs(foundKey.status_date).format('MM/DD/YYYY') : null,
            })
        })
        return statusMapValues
    }, [created_at, payment_request_status])
    const statusCellValues = useMemo(() => {
        if (payment_request_status?.length > 1) {
            return paymentRequestStatusMap[payment_request_status.at(-1)?.status]
        }
        if (dayjs().isBefore(invoice_date))
            return {
                text: 'Scheduled',
                color: 'var(--color-light-purple)',
            }
        return paymentRequestStatusMap['Payment Requested']
    }, [payment_request_status, invoice_date])
    if (requestsFetching || isLoading) return <Loader />
    return (
        <SidebarModal title="Payment Request Details" {...rest}>
            <Flex column p="0 50px">
                <PaymentDetailRow justify="space-between">
                    <Flex gap="10" centerAlign justify="space-between">
                        <Text variant="h3">
                            {buyer?.organization?.legal_name ?? buyer?.organization?.name}
                        </Text>
                        <StatusCell
                            value={statusCellValues?.text}
                            statusColor={statusCellValues?.color}
                        />
                    </Flex>
                    <DetailValueText>{formatCurrency(payment_amount)}</DetailValueText>
                </PaymentDetailRow>
                <PaymentDetailRow justify="space-between">
                    <Flex centered>
                        <Text variant="h3">Advance Fee</Text>
                        <Tooltip
                            tooltipText={`This is the ${
                                vendor_fee_percentage * 100
                            }% fee that is paid to Market for the advance.`}
                        />
                    </Flex>
                    <DetailValueText>
                        {formatCurrency(payment_amount - vendor_final_payment_amount)}
                    </DetailValueText>
                </PaymentDetailRow>
                <PaymentDetailRow justify="space-between">
                    <Text variant="h3">Net Amount</Text>
                    <DetailValueText>
                        {/* TODO: Pull this from persisted amount instead of in memory calculation */}
                        {formatCurrency(vendor_final_payment_amount)}
                    </DetailValueText>
                </PaymentDetailRow>
                <PaymentDetailRow justify="space-between">
                    <Text variant="h3">Invoice Date</Text>
                    <DetailValueText>{dayjs(invoice_date).format('YYYY/MM/DD')}</DetailValueText>
                </PaymentDetailRow>
                <PaymentDetailRow justify="space-between">
                    <Text variant="h3">Payment Due Date</Text>
                    <DetailValueText>
                        {dayjs(payment_due_date).format('YYYY/MM/DD')}
                    </DetailValueText>
                </PaymentDetailRow>
                <Flex gap="120" justify="space-between" p="20px 0" m="0 0 80px">
                    <Text variant="h3">Memo</Text>
                    <Text variant="p2" secondary={!memo} right>
                        {memo ?? 'N/A'}
                    </Text>
                </Flex>
                <ProgressBar
                    title="Payment Request Status"
                    currValue={statusValue}
                    totalValue={3}
                    progressMapValues={progressMapValues}
                    hasError={statusCellValues?.text === 'Error'}
                />
                <Flex m="90px 0 0" column centered>
                    <InvoiceDetailsButton
                        invoiceDetails={{
                            billedTo: buyer?.organization?.legal_name ?? buyer?.organization?.name,
                            billedFrom:
                                data?.data?.organization?.legal_name ??
                                data?.data?.organization?.name,
                            billedOn: dayjs(invoice_date).format('MMMM D, YYYY'),
                            dueDate: dayjs(payment_due_date).format('MMMM D, YYYY'),
                            paymentRequestId,
                        }}
                        disabled={payment_request_status?.at(-1)?.status === 'Payment Requested'}
                    >
                        View Invoice
                    </InvoiceDetailsButton>
                </Flex>
            </Flex>
        </SidebarModal>
    )
}

export default VendorRequestDetailsModal
