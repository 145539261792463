import styled from '@emotion/styled'
import dayjs from 'dayjs'
import Flex from './Flex'
import Link from './Link'

const footerYear = dayjs().format('YYYY')

const FooterContainer = styled(Flex)`
    padding: 28px 50px;
    margin-right: 36px;
    justify-content: space-between;
    align-items: center;
`

const StyledLink = styled(Link)`
    margin-right: 24px;
`

const Footer = () => (
    <FooterContainer>
        <Flex>
            <StyledLink href="https://www.getmarket.com/privacy-policy">Privacy Policy</StyledLink>
            {/* <StyledLink internal to="/discover">
        Security
      </StyledLink>
      <StyledLink internal to="/discover">
        Careers
      </StyledLink>
      <StyledLink internal to="/discover">
        Blog
      </StyledLink> */}
        </Flex>
        <div>Copyright {footerYear} © Trust Software Inc.</div>
    </FooterContainer>
)

export default Footer
