import { useQuery, useMutation } from '@tanstack/react-query'
import queryClient from 'api/query'
import { errorToast } from 'common/toasts'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import Flex from 'common/components/Flex'
import Loader from 'common/components/Loader'
import PageSection from 'common/components/PageSection'
import { getBuyers, updateBuyerInvite } from 'organizations/api'
import { useCallback, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import userIcon from 'assets/svg/user.svg'
import cardIcon from 'assets/svg/card-icon.svg'
import rightIcon from 'assets/svg/right.svg'
import exportIcon from 'assets/svg/export.svg'
import Text from 'common/components/Text'
import dayjs from 'dayjs'
import { exportPaymentRequestsByBuyer, getBuyerPaymentRequests } from 'paymentRequests/api'
import { PAYMENT_REQUEST_STATUS } from 'common/enums'
import ReinviteButton from 'common/components/ReinviteButton'
import RequestPaymentModal from 'paymentRequests/components/RequestPaymentModal'
import Button from 'common/components/Button'
import TextInput from 'common/components/TextInput'
import Icon from 'common/components/Icon'
import styled from '@emotion/styled'
import { formatCurrency } from 'common/util'
import Table from 'common/table/Table'
import StatusCell from 'common/table/StatusCell'
import { paymentRequestStatusMap } from 'common/statusMaps'

const BuyerDetailTile = ({ title, value }) => (
    <Flex gap="10" p="0 0 20px" column>
        <Text variant="h3">{title}</Text>
        <Text variant="p1">{value}</Text>
    </Flex>
)

BuyerDetailTile.propTypes = {
    title: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

const SizedIconButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        width: 24px;
        height: 24px;
        path {
            transition: fill 0.2s ease;
            fill: ${({ buttonColor }) => buttonColor};
        }
    }
    :hover {
        svg {
            path {
                transition: opacity 0.2s ease;
                opacity: 0.8;
            }
        }
    }
`

const RightIcon = styled.img`
    width: 16px;
    height: 16px;
`

const ExportIcon = styled.img`
    width: 16px;
    height: 16px;
`

const buyerPaymentRequestColumns = [
    {
        header: 'Created On',
        accessorKey: 'created_at',
        cell: (row) => <span>{dayjs(row.getValue()).format('MM/DD/YYYY')}</span>,
        sortingFn: 'datetime',
    },
    {
        header: 'Due Date',
        accessorKey: 'payment_due_date',
        cell: (row) => <span>{dayjs(row.getValue()).format('MM/DD/YYYY')}</span>,
        sortingFn: 'datetime',
    },
    {
        header: 'Payment Status',
        accessorFn: (row) => {
            if (row.payment_request_status.length > 1) {
                return paymentRequestStatusMap[row.payment_request_status.at(-1)?.status].text
            }
            if (dayjs().isBefore(row.invoice_date)) return 'Scheduled'
            return 'Requested'
        },
        id: 'payment_status',
        sortDescFirst: true,
        cell: (row) => {
            const statusBackgroundColor =
                Object.values(paymentRequestStatusMap).find((s) => s.text === row.getValue())
                    ?.color ?? 'var(--color-light-purple)'
            return (
                <StatusCell value={row.getValue()} statusColor={statusBackgroundColor} tableCell />
            )
        },
        sortingFn: (rowA, rowB) =>
            paymentRequestStatusMap[rowA.original.payment_request_status.at(-1)?.status].value <
            paymentRequestStatusMap[rowB.original.payment_request_status.at(-1)?.status].value
                ? -1
                : 1,
    },
    {
        header: 'Amount',
        accessorKey: 'payment_amount',
        cell: (row) => <span>{formatCurrency(row.getValue())}</span>,
        sortingFn: 'basic',
    },
    {
        header: ' ',
        accessorKey: 'id',
        cell: () => <RightIcon src={rightIcon} alt="Right arrow icon" />,
    },
]
const GreenIconButton = styled(SizedIconButton)`
    svg {
        path {
            fill: var(--color-green);
        }
    }
`

const RedIconButton = styled(SizedIconButton)`
    svg {
        path {
            fill: var(--color-red);
        }
    }
`

const BuyerDetails = () => {
    const { buyerId } = useParams()
    const navigate = useNavigate()
    const [modalOpen, setModalOpen] = useState(false)
    const [editing, setEditing] = useState(false)
    const [toggle, setToggle] = useState(false)

    const { data, isLoading } = useQuery({
        queryKey: ['listBuyers'],
        queryFn: getBuyers,
    })
    const getBuyerPaymentRequestsById = useCallback(
        () => getBuyerPaymentRequests(buyerId),
        [buyerId]
    )
    const { data: paymentRequests } = useQuery({
        queryKey: [`buyer${buyerId}PaymentRequests`],
        queryFn: getBuyerPaymentRequestsById,
        enabled: !!buyerId,
    })
    const { organization } = useMemo(
        () => data?.data.find((buyer) => buyer.id === buyerId) ?? {},
        [data, buyerId]
    )
    const { name, legal_name, created_at, created_by_user } = useMemo(
        () => organization ?? {},
        [organization]
    )
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            name: name ?? legal_name,
            business_email: created_by_user?.email,
        },
    })
    const { mutate, isLoading: updating } = useMutation({
        mutationKey: ['updateBuyerInvite'],
        mutationFn: updateBuyerInvite,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [`listBuyers`] })
            setEditing(false)
        },
        onError: () => {
            errorToast('An error occurred updating the customers information.')
        },
    })
    const noPaymentRequests = !paymentRequests?.data.length
    const { isLoading: exporting, mutate: exportMutate } = useMutation({
        mutationKey: ['paymentRequestExportByBuyer'],
        mutationFn: exportPaymentRequestsByBuyer,
    })
    const notAcceptedInvite = useMemo(
        () =>
            paymentRequests?.data.length === 1 &&
            paymentRequests?.data?.[0]?.status === PAYMENT_REQUEST_STATUS.PENDING,
        [paymentRequests]
    )
    const exportPaymentRequests = useCallback(
        () => exportMutate({ buyerId, buyerName: legal_name ?? name ?? 'Buyer' }),
        [exportMutate, buyerId, name, legal_name]
    )
    const onSubmit = (values) => mutate({ values, buyerId })

    if (isLoading) return <Loader />
    return (
        <>
            <Flex column gap="30" p="20px 0">
                <PageSection
                    icon={userIcon}
                    title="Customer Details"
                    controls={
                        notAcceptedInvite && <ReinviteButton view="buyer" buyerId={buyerId} />
                    }
                    tableSection
                >
                    <Flex gap="40" p="10px 20px">
                        {editing ? (
                            <Flex gap="20" centerAlign>
                                <TextInput
                                    errors={errors}
                                    name="name"
                                    showLabel
                                    inputRef={() => register('name', { required: true })}
                                    label="Business Name"
                                />
                                <TextInput
                                    errors={errors}
                                    name="business_email"
                                    showLabel
                                    inputRef={() => register('business_email', { required: true })}
                                    label="Business Email"
                                />
                                <GreenIconButton
                                    loading={updating}
                                    variant="iconOnly"
                                    icon="circleCheck"
                                    buttonColor="var(--color-green)"
                                    onClick={handleSubmit(onSubmit)}
                                />
                                <RedIconButton
                                    onClick={() => setEditing(false)}
                                    variant="iconOnly"
                                    icon="circleX"
                                    buttonColor="var(--color-red)"
                                />
                            </Flex>
                        ) : (
                            <>
                                <BuyerDetailTile title="Customer Name" value={name ?? legal_name} />
                                <BuyerDetailTile
                                    title="Customer Email"
                                    value={created_by_user?.email}
                                />
                                <BuyerDetailTile
                                    title="Date Joined"
                                    value={dayjs(created_at).format('YYYY-MM-DD')}
                                />
                                <BuyerDetailTile
                                    title="Payment Requests"
                                    value={paymentRequests?.data.length ?? 0}
                                />
                            </>
                        )}
                        {(noPaymentRequests || notAcceptedInvite) && !editing && (
                            <Flex
                                centered
                                style={{ cursor: 'pointer' }}
                                onClick={() => setEditing(true)}
                            >
                                <Icon name="pencil" />
                            </Flex>
                        )}
                    </Flex>
                </PageSection>
                <PageSection
                    icon={cardIcon}
                    title="Payment Requests"
                    controls={
                        <Flex gap="10">
                            <Button
                                size="small"
                                variant="invert"
                                onClick={exportPaymentRequests}
                                loading={exporting}
                            >
                                <Flex gap="10" centered>
                                    <ExportIcon src={exportIcon} alt="Export icon" />
                                    Export CSV
                                </Flex>
                            </Button>
                            <Button size="small" onClick={() => setModalOpen(true)}>
                                Request Payment
                            </Button>
                        </Flex>
                    }
                    tableSection
                >
                    <Table
                        data={paymentRequests?.data ?? []}
                        columns={buyerPaymentRequestColumns}
                        onRowClick={(rowId) => navigate(`../../payment-requests/${rowId}`)}
                    />
                    {!paymentRequests?.data?.length && (
                        <Flex p="150px" centered>
                            <Text variant="h3" bold>
                                This customer currently has no payment requests.
                            </Text>
                        </Flex>
                    )}
                </PageSection>
            </Flex>
            <RequestPaymentModal
                isOpen={modalOpen}
                onRequestClose={() => {
                    setModalOpen(false)
                }}
                refresh={() => {
                    setToggle(!toggle)
                }}
                buyerId={buyerId}
            />
        </>
    )
}

export default BuyerDetails
