import { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'
import styled from '@emotion/styled'
import Button from 'common/components/Button'
import SinglePageCard from 'common/components/SinglePageCard'
import TextInput from 'common/components/TextInput'
import { requestPasswordReset } from 'user/api'

const SubmitButton = styled(Button)`
    margin-top: 22px;
`

const PasswordResetRequest = () => {
    const [phase, setPhase] = useState('idle')
    const { isLoading, mutate } = useMutation({
        mutationFn: requestPasswordReset,
        onSuccess: () => setPhase('success'),
    })
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm()

    const onSubmit = useCallback(({ email }) => mutate(email), [mutate])

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <SinglePageCard
                logo
                cardLogo
                title="Reset your password"
                subHead={
                    phase === 'success'
                        ? 'If you have an active Trust account, you will receive an email shortly.'
                        : "Enter your email and we'll send you a password reset link"
                }
                width="480px"
            >
                {phase !== 'success' && (
                    <>
                        <TextInput
                            showLabel
                            name="email"
                            type="email"
                            label="Email"
                            inputRef={() =>
                                register('email', {
                                    required: true,
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: 'Please enter a valid email address.',
                                    },
                                })
                            }
                            errors={errors}
                            placeholder="you@yourcompany.com"
                        />
                        <SubmitButton size="full" onClick={handleSubmit} loading={isLoading}>
                            Send Link
                        </SubmitButton>
                    </>
                )}
            </SinglePageCard>
        </form>
    )
}

export default PasswordResetRequest
