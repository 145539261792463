import styled from '@emotion/styled'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import Flex from './Flex'

const TextAreaInput = styled.textarea`
    all: unset;
    width: ${({ width }) => width || '100%'};
    height: ${({ height }) => height || '188px'};
    box-sizing: border-box;
    border: 1px solid transparent;
    resize: none;
    background-color: var(--color-snow-white);
    padding: 12px 10px;
    transition: border-color 0.2s ease;
    border-radius: 6px;
    :hover {
        background-color: var(--color-snow-white);
        border-color: var(--color-medium-light-gray);
    }
    :focus {
        border-color: var(--color-blue);
        background-color: var(--color-snow-white);
    }
    :disabled {
        border-color: transparent;
        color: var(--color-dark-gray);
        background-color: var(--color-snow-white);
    }
    ::placeholder {
        color: var(--color-medium-light-gray);
        font-size: 16px;
    }
`

const Label = styled.label`
    font-size: 14px;
    font-weight: 200;
    color: var(--color-dark-gray);
`

const ErrorMessage = styled.div`
    color: var(--color-red);
    font-size: 10px;
    font-style: italic;
    left: 0;
    bottom: 6px;
    position: absolute;
`

const TextArea = ({ inputRef = null, name, label, errors, ...rest }) =>
    inputRef ? (
        <Flex gap="5" p={errors && isEmpty(errors[inputRef().name]) ? '0 0 17px' : '0'} column>
            {label && <Label htmlFor={inputRef().name}>{label}</Label>}
            <TextAreaInput {...inputRef()} {...rest} />
            {errors && !isEmpty(errors[inputRef().name]) && (
                <ErrorMessage>{errors[inputRef().name].message}</ErrorMessage>
            )}
        </Flex>
    ) : (
        <Flex gap="5" p={errors && isEmpty(errors[name]) ? '0 0 17px' : '0'} column>
            {label && <Label htmlFor={name}>{label}</Label>}
            <TextAreaInput name={name} {...rest} />
            {errors && !isEmpty(errors[name]) && (
                <ErrorMessage>{errors[name].message}</ErrorMessage>
            )}
        </Flex>
    )

TextArea.propTypes = {
    inputRef: PropTypes.func,
    name: PropTypes.string,
    label: PropTypes.string,
    errors: PropTypes.object,
}

export default TextArea
