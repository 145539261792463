import callApi from 'api/callApi'
import { BUYER_ROUTES } from 'api/endpoints'

export const fetchPaymentAgreements = () => callApi(BUYER_ROUTES.GET_PAYMENT_AGREEMENTS)

export const createPaymentAgreement = ({ paymentRequestId, paymentTermsOptionId }) =>
    callApi(
        `${BUYER_ROUTES.CREATE_PAYMENT_AGREEMENT}?payment_term=${paymentTermsOptionId}&request_id=${paymentRequestId}`,
        'post',
        {
            data: {},
        }
    )
