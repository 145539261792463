import { AnimatePresence, motion } from 'framer-motion'
import { useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Flex from './Flex'
import Text from './Text'

const TotalProgess = styled.div`
    background: var(--color-light-gray);
    height: 18px;
    width: 100%;
    border-radius: 18px;
    position: relative;
    margin-top: 10px;
`

const CurrentProgess = styled(motion.div, {
    shouldForwardProp: (prop) => prop !== 'hasError',
})`
    background: ${({ hasError }) =>
        hasError ? 'var(--color-light-red)' : 'var(--color-pastel-green)'};
    height: 100%;
    border-radius: 18px;
`

const ProgressMarkerWrap = styled(Flex)`
    height: 75px;
    width: 100px;
    position: absolute;
    left: ${({ markerPosition }) => markerPosition};
    top: ${({ completed = false }) => (completed ? -3 : 0)}px;
    overflow: visible;
`

const ProgressMarkerPoint = styled.div`
    ${({ completed = false }) =>
        completed
            ? `
                height: 24px;
                width: 24px;
                margin: 0px;
                margin-bottom: 2px;
                border-radius: 24px;
                border: 2px solid var(--color-black);
                background-color: var(--color-black);
            `
            : `
                height: 8px;
                width: 8px;
                margin: 5px;
                margin-bottom: 10px;
                border-radius: 8px;
                background-color: var(--color-black-one-third-opacity);
            `}
`

const ProgressMarker = ({ markerPosition, text, subtext, completed, firstValue, lastValue }) => (
    <ProgressMarkerWrap
        markerPosition={markerPosition}
        column
        align={lastValue ? 'flex-end' : 'flex-start'}
        centerAlign={!firstValue && !lastValue}
        completed={completed}
    >
        <ProgressMarkerPoint completed={completed} />
        <Text variant="h3" m="5px 0">
            {text}
        </Text>
        <Text variant="p2">{subtext}</Text>
    </ProgressMarkerWrap>
)

ProgressMarker.propTypes = {
    markerPosition: PropTypes.string,
    text: PropTypes.string,
    subtext: PropTypes.string,
    completed: PropTypes.bool,
    firstValue: PropTypes.bool,
    lastValue: PropTypes.bool,
}

const ProgressBar = ({ title, currValue, totalValue, progressMapValues, hasError = false }) => {
    const calculateWidth = useMemo(
        () => `${((currValue / totalValue) * 100).toFixed(2)}%`,
        [currValue, totalValue]
    )
    const markerPositions = useMemo(() => {
        const newArr = Array.from({ length: totalValue }, (v, i) =>
            i ? `calc(${(i / totalValue) * 100}% - 50px)` : `${(i / totalValue) * 100}%`
        )
        return [...newArr, 'calc(100% - 100px)']
    }, [totalValue])
    return (
        <Flex m="0 0 50px" gap="10" column>
            <Text variant="h2">{title}</Text>
            <TotalProgess>
                <AnimatePresence>
                    <CurrentProgess
                        initial={{ width: '0%' }}
                        animate={{ width: calculateWidth }}
                        transition={{ delay: 0.5 }}
                        hasError={hasError}
                    />
                </AnimatePresence>
                {markerPositions.map((pos, i) => (
                    <ProgressMarker
                        key={pos}
                        markerPosition={pos}
                        completed={currValue === i}
                        text={progressMapValues[i].text}
                        subtext={progressMapValues[i].subtext}
                        firstValue={!i}
                        lastValue={i === totalValue}
                    />
                ))}
            </TotalProgess>
        </Flex>
    )
}

ProgressBar.propTypes = {
    title: PropTypes.string,
    currValue: PropTypes.number,
    totalValue: PropTypes.number,
    progressMapValues: PropTypes.array,
    hasError: PropTypes.bool,
}

export default ProgressBar
