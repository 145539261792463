/* eslint-disable import/prefer-default-export */
import callApi from 'api/callApi'
import { PAYMENT_REQUEST_ROUTES, ROOT_ROUTES } from 'api/endpoints'
import { downloadBlob, getFilenameFromHeaders } from 'common/util'

export const getAllPaymentRequests = () => callApi(ROOT_ROUTES.PAYMENT_REQUESTS)

export const getBuyerPaymentRequests = (buyerId) =>
    callApi(PAYMENT_REQUEST_ROUTES.GET_PAYMENT_REQUESTS_BY_BUYER(buyerId))

export const requestPayment = ({ payment_amount, invoice_date, buyerId, ...rest }) =>
    callApi(PAYMENT_REQUEST_ROUTES.CREATE_PAYMENT_REQUEST_FOR_BUYER(buyerId), 'POST', {
        data: {
            payment_amount: payment_amount * 100,
            invoice_date: `${invoice_date}T00:00`,
            ...rest,
        },
    })

export const exportPaymentRequestsByBuyer = ({ buyerId, buyerName }) =>
    callApi(PAYMENT_REQUEST_ROUTES.EXPORT_PAYMENT_REQUESTS_BY_BUYER(buyerId), 'get', {
        responseType: 'blob',
    }).then(async (res) => {
        downloadBlob(res.data, `${buyerName.split(' ').join('')}PaymentRequests.csv`)
    })

export const exportAllPaymentRequests = (vendorName) =>
    callApi(PAYMENT_REQUEST_ROUTES.EXPORT_ALL_PAYMENT_REQUESTS, 'get', {
        responseType: 'blob',
    }).then(async (res) => {
        downloadBlob(res.data, `${vendorName.split(' ').join('')}PaymentRequests.csv`)
    })

export const downloadInvoicePDF = (paymentRequestId) =>
    callApi(PAYMENT_REQUEST_ROUTES.DOWNLOAD_INVOICE_PDF(paymentRequestId), 'get', {
        responseType: 'blob',
    }).then(async (res) => {
        downloadBlob(res.data, getFilenameFromHeaders(res.headers))
    })
