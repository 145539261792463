import PropTypes from 'prop-types'
import { Link as InternalLink } from 'react-router-dom'
import styled from '@emotion/styled'

const variants = {
    primaryColor: `
  color: var(--color-blue);
  :visited {
    color: var(--color-light-blue);
  }
  :hover {
    color: var(--color-light-blue);
  }
  `,
    nugget: `
  color: var(--color-light-blue);
  :hover {
    color: var(--color-blue);
  }
  :visited {
    color: var(--color-light-blue);
  }
  `,
    summary: `
  color: var(--color-blue);
  text-decoration: underline;
  :hover {
    color: var(--color-light-blue);
  }
  :visited {
    color: var(--color-blue);
  }
  `,
    default: '',
}

const linkStyles = `
  text-decoration: none;
  color: var(--color-black);
  transition: color .2s ease;
  border: 1px solid;
  border-color: transparent;
  :visited {
    color: var(--color-black);
  }
  :hover {
    color: var(--color-medium-dark-gray);
  }
`

const PlainInternalLink = styled(InternalLink)`
    ${linkStyles}
    ${({ variant }) => variants[variant]}
`
const PlainExternalLink = styled.a`
    ${linkStyles}
    ${({ variant }) => variants[variant]}
`

const Link = ({ internal = false, externalRedirect = false, children, variant = '', ...rest }) => {
    if (internal) {
        return (
            <PlainInternalLink variant={variant} {...rest}>
                {children}
            </PlainInternalLink>
        )
    }
    if (externalRedirect) {
        return (
            <PlainExternalLink variant={variant} {...rest}>
                {children}
            </PlainExternalLink>
        )
    }
    return (
        <PlainExternalLink variant={variant} target="_blank" rel="noreferrer" {...rest}>
            {children}
        </PlainExternalLink>
    )
}

Link.propTypes = {
    internal: PropTypes.bool,
    externalRedirect: PropTypes.bool,
    variant: PropTypes.string,
    children: PropTypes.node,
}

export default Link
