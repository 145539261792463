import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import styled from '@emotion/styled'
import closeIcon from 'assets/svg/icons/circleX.svg'
import Flex from 'common/components/Flex'
import Text from 'common/components/Text'
import Logo from 'common/components/Logo'
import Modal from 'common/components/Modal'
import Button from 'common/components/Button'
import { useMutation } from '@tanstack/react-query'
import { downloadInvoicePDF } from 'paymentRequests/api'

const ViewInvoiceWrapper = styled(Flex)`
    width: 600px;
    padding: 70px;
    position: relative;
`

const InvoiceInformation = styled(Flex)`
    width: 100%;
    margin: 20px 0;
    & > div {
        justify-content: space-between;
    }
`

const CloseIcon = styled.img`
    position: absolute;
    top: 30px;
    right: 30px;
    width: 25px;
    height: 25px;
    cursor: pointer;
`

const InvoiceDetailsModal = ({ invoiceDetails, onRequestClose, ...rest }) => {
    const { billedTo, billedFrom, billedOn, dueDate, paymentRequestId } = useMemo(
        () => invoiceDetails ?? {},
        [invoiceDetails]
    )
    const { isLoading, mutate } = useMutation({
        mutationKey: ['downloadInvoicePDF'],
        mutationFn: downloadInvoicePDF,
    })
    const downloadInvoice = useCallback(() => mutate(paymentRequestId), [mutate, paymentRequestId])
    return (
        <Modal onRequestClose={onRequestClose} {...rest}>
            <ViewInvoiceWrapper gap="30" column centered>
                <CloseIcon src={closeIcon} alt="Close icon" onClick={onRequestClose} />
                <Logo width="180px" />
                <InvoiceInformation gap="40" column>
                    <Flex centerAlign>
                        <Text>Billed To:</Text>
                        <Text>{billedTo}</Text>
                    </Flex>
                    <Flex centerAlign>
                        <Text>Billed From:</Text>
                        <Text>{billedFrom}</Text>
                    </Flex>
                    <Flex centerAlign>
                        <Text>Billed On:</Text>
                        <Text>{billedOn}</Text>
                    </Flex>
                    <Flex centerAlign>
                        <Text>Due Date:</Text>
                        <Text>{dueDate}</Text>
                    </Flex>
                </InvoiceInformation>
                <Button size="full" onClick={downloadInvoice} loading={isLoading}>
                    Download PDF
                </Button>
                <Flex gap="10" centerAlign>
                    <Text variant="p2">Powered by</Text>
                    <Logo width="90px" />
                </Flex>
            </ViewInvoiceWrapper>
        </Modal>
    )
}

InvoiceDetailsModal.propTypes = {
    invoiceDetails: PropTypes.shape({
        billedTo: PropTypes.string,
        billedFrom: PropTypes.string,
        billedOn: PropTypes.string,
        dueDate: PropTypes.string,
        paymentRequestId: PropTypes.string,
    }),
    onRequestClose: PropTypes.func,
}

export default InvoiceDetailsModal
