const colors = {
    // dark: {
    //     blackBlue: '#12101B',
    //     brightBlue: '#385DF7',
    //     darkBlue: '#0031FF',
    //     grayBlue: '#252230',
    //     darkGray: '#312F3C',
    //     lighterDarkGray: '#302C3E',
    //     mediumGrayBlue: '#2C2936',
    //     lightGrayBlue: '#322F40',
    //     mediumGray: '#868686',
    //     lightGray: '#4F4C5A',
    //     purplishGray: '#9A979F',
    //     mediumDarkGray: '#D6D4E8',
    //     mintGreen: '#00EF7C',
    //     green: '#2CB473',
    //     white: '#FFFFFF',
    //     red: '#F24E2B',
    //     errorRed: '#FF5E5E',
    //     deepPink: '#C30052',
    //     softPink: '#FFF3F8',
    //     orange: '#FF7245',
    // },
    black: '#222222',
    blackSemiOpaque: '#222222bb',
    blackLowOpacity: '#22222226',
    blackOneThirdOpacity: '#22222254',
    blackOneQuarterOpacity: '#22222240',
    darkGray: '#607180',
    mediumDarkGray: '#8895a5',
    mediumLightGray: '#bdbdbd',
    lightGray: '#e0e0e0',
    iceWhite: '#f5f7f8',
    blue: '#385df7',
    lightBlue: '#788cfc',
    lightPastelBlue: '#F3F4FF',
    pastelBlue: '#e3e6ff',
    backgroundBlue: '#d8ecff',
    lightBackgroundBlue: '#edf7ff',
    purple: '#7538f7',
    lightPurple: '#e8ebff',
    red: '#cc444b',
    lightRed: '#ffcdcd',
    snowWhite: '#f2f4fa',
    green: '#1fa866',
    lightGreen: '#dbffe0',
    lightPastelGreen: '#eaffed',
    pastelGreen: '#b7fdc1',
    backgroundGreen: '#eafeec',
    lightYellow: '#fffbd8',
    white: '#ffffff',
}

export default colors
