import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { capitalize, isEmpty } from 'lodash'
import { useMemo } from 'react'
import Flex from './Flex'

const StyledInput = styled.input`
    all: unset;
    box-sizing: border-box;
    padding: 11px 10px;
    border-radius: 6px;
    transition: border-color 0.2s ease, background-color 0.2s ease, color 0.2s ease;
    width: 100%;
    position: absolute
    text-align: left;
    background-color: var(--color-snow-white);
    color: ${({ noValue }) => (noValue ? 'var(--color-medium-light-gray)' : 'var(--color-black)')};
    border: 1px solid transparent;
    :hover {
        background-color: var(--color-snow-white);
        border-color: var(--color-medium-light-gray);
    }
    :focus {
        border-color: var(--color-blue);
        background-color: var(--color-snow-white);
    }
    :disabled {
        border-color: transparent;
        color: var(--color-dark-gray);
        background-color: var(--color-snow-white);
    }
    ${({ hasError }) =>
        hasError &&
        `border-color: var(--color-red);
        background-color: var(--color-light-red);
        :hover {
            border-color: var(--color-red);
            background-color: var(--color-light-red);
        }
        :focus {
            border-color: var(--color-red);
            background-color: var(--color-light-red);
        }
    `}
    ${({ hasError, noValue }) => hasError && !noValue && `color: var(--color-red);`}
    ::placeholder {
        color: var(--color-medium-light-gray);
        font-size: 16px;
    }
    &::-webkit-calendar-picker-indicator {
        filter: invert(${({ noValue }) => (noValue ? '0.8' : '0')});
    }
`

const ColorHolder = styled(Flex)`
    width: 100%;
    ${({ errorPotential, hasError }) => errorPotential && !hasError && 'padding-bottom: 17px'};
`

const InputWrapper = styled.div`
    position: relative;
    height: 45px;
`

const ErrorMessage = styled.div`
    color: var(--color-red);
    font-size: 10px;
    font-style: italic;
`

const Label = styled.label`
    font-size: 14px;
    font-weight: 200;
    color: var(--color-dark-gray);
`

const DateInput = ({
    inputRef = () => ({}),
    label,
    className,
    errors,
    errorMessage,
    watch,
    ...rest
}) => {
    const inputName = useMemo(() => inputRef().name, [inputRef])
    const hasError = errors && !isEmpty(errors[inputName])
    const eMessage =
        errorMessage ||
        (hasError && errors[inputName]?.message) ||
        `${capitalize(label ?? inputName)} is a required field.`
    const currValue = watch(inputName)
    const inputProps = useMemo(
        () => ({
            type: 'date',
            hasError,
            noValue: !currValue,
        }),
        [currValue, hasError]
    )
    return (
        <ColorHolder
            className={className}
            errorPotential={errors}
            hasError={hasError}
            gap="5"
            column
        >
            {label && <Label htmlFor={inputName}>{label}</Label>}
            <InputWrapper>
                <StyledInput {...inputProps} {...inputRef()} {...rest} />
            </InputWrapper>
            {hasError && <ErrorMessage>{eMessage}</ErrorMessage>}
        </ColorHolder>
    )
}

DateInput.propTypes = {
    inputRef: PropTypes.func,
    label: PropTypes.string,
    className: PropTypes.string,
    errors: PropTypes.object,
    errorMessage: PropTypes.string,
    watch: PropTypes.func,
}

export default DateInput
