import PropTypes from 'prop-types'
import styled from '@emotion/styled'
// import BannerNotification from 'common/components/BannerNotification'
// import SplashScreen from 'views/SplashScreen'
import { useLocation } from 'react-router-dom'
import { useMemo } from 'react'
import { unauthenticatedRoutes } from 'routing/navItems'
import usePageInit from 'common/hooks/usePageInit'
import Footer from './Footer'
import Header from './Header'
import Sidebar from './Sidebar'
import CustomToaster from './Toaster'

const Grid = styled.div`
    position: relative;
    display: grid;
    grid-template-${({ showPublicLayout }) =>
        showPublicLayout ? 'columns: auto' : 'rows: auto 1fr'};
    min-height: 100%;
    width: 100%;
`

const MainColumn = styled.div`
    padding: 0 36px 0 36px;
    margin: 0 auto;
    display: grid;
    width: 1200px;
    grid-template-rows: auto 1fr auto;
`

const Content = styled.div`
    padding: 0px 4px;
`

const PublicHeader = styled.div`
    height: 80px;
`

const Layout = ({ children, showSidebar = false }) => {
    const { pathname } = useLocation()
    const showPublicLayout = useMemo(
        () =>
            !!unauthenticatedRoutes.some((r) => pathname.includes(r.path)) ||
            pathname.includes('onboarding') ||
            pathname.includes('new-request'),
        [pathname]
    )
    usePageInit()
    return (
        <>
            {/* <BannerNotification /> */}
            <Grid showPublicLayout={showPublicLayout}>
                <CustomToaster />
                {(showSidebar || !showPublicLayout) && <Sidebar />}
                <MainColumn>
                    {showPublicLayout ? <PublicHeader /> : <Header />}
                    <Content>{children}</Content>
                    <Footer />
                </MainColumn>
            </Grid>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    showSidebar: PropTypes.bool,
}

export default Layout
