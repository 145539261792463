import dayjs from 'dayjs'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import Flex from 'common/components/Flex'
import Text from 'common/components/Text'
import ConditionalWrapper from 'common/components/ConditionalWrapper'
import { fetchCurrentUser } from 'user/api'
import { useQuery } from '@tanstack/react-query'

const Label = styled.div`
    font-size: 14px;
    font-weight: 200;
    color: var(--color-dark-gray);
`

const Grid = styled.div`
    display: grid;
    gap: 12px;
    grid-template-columns: 1fr 1fr;
`

const InputStyledText = styled(Text)`
    padding: 12px 10px;
    border-radius: 6px;
    background-color: var(--color-snow-white);
    color: var(--color-dark-gray);
    min-height: 42px;
    font-weight: 400;
`

const PaymentDueDateFields = ({ invoiceDate, view }) => {
    const { data } = useQuery({
        queryKey: ['currentUser'],
        queryFn: fetchCurrentUser,
    })
    const orgsPaymentTermsDays = useMemo(
        () => data?.data.organization.organization_vendor.default_payment_terms_days ?? 0,
        [data]
    )
    const dueDate = useMemo(
        () => dayjs(invoiceDate).add(orgsPaymentTermsDays, 'day').format('MM/DD/YYYY'),
        [invoiceDate, orgsPaymentTermsDays]
    )
    return (
        <ConditionalWrapper
            condition={view !== 'buyer'}
            wrapper={(children) => <Grid>{children}</Grid>}
        >
            <Flex column>
                <Label>Payment Terms</Label>
                <InputStyledText m="4px 0 20px 0">{orgsPaymentTermsDays}</InputStyledText>
            </Flex>
            <Flex column>
                <Label>Payment Due Date{view === 'buyer' && ' (optional)'}</Label>
                <InputStyledText m="4px 0 20px 0">{invoiceDate && dueDate}</InputStyledText>
            </Flex>
        </ConditionalWrapper>
    )
}

PaymentDueDateFields.propTypes = {
    invoiceDate: PropTypes.string,
    view: PropTypes.string,
}

export default PaymentDueDateFields
