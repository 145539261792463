import { Link, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { isURL } from 'validator'
import styled from '@emotion/styled'
import { errorToast } from 'common/toasts'
import Flex from 'common/components/Flex'
import { fetchCurrentUser } from 'user/api'
import { useMutation, useQuery } from '@tanstack/react-query'
import queryClient from 'api/query'
import { patchBuyerOrganization } from 'organizations/api'
import Loader from 'common/components/Loader'
import TextInput from 'common/components/TextInput'
import AddressInput from 'common/components/AddressInput'
import Text from 'common/components/Text'
import Button from 'common/components/Button'
import Tile from 'common/components/Tile'
import { useEffect } from 'react'

const StyledForm = styled.form`
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 10px;
`

const BusinessDetailFormRow = styled(Flex)`
    justify-content: space-between;
`

const InputWrapper = styled(Flex)`
    width: 45%;
`

const TermsWrap = styled(Flex)`
    margin: 5px 0 20px 0;
`

const TermsGroup = styled(Flex)`
    padding: 4px;
    border-radius: 4px;
    input {
        margin-right: 10px;
    }
    a {
        color: var(--color-blue);
    }
`

const validateEIN = (input) => {
    const patternEIN = /^[0-9]\d?-\d{7}$/
    return !patternEIN.test(input) ? 'Please enter a valid EIN (eg. 11-1111111)' : true
}

const BusinessDetails = () => {
    const {
        register,
        handleSubmit,
        trigger,
        setValue,
        formState: { errors },
    } = useForm()
    const { isLoading } = useQuery({
        queryKey: ['currentUser'],
        queryFn: fetchCurrentUser,
    })
    const navigate = useNavigate()
    const {
        isLoading: updating,
        mutate,
        isSuccess,
    } = useMutation({
        mutationKey: ['patchUserOrganization'],
        mutationFn: patchBuyerOrganization,
        onError: () => errorToast(),
    })
    useEffect(() => {
        if (isSuccess) {
            queryClient.invalidateQueries({ queryKey: ['currentUser'] })
            navigate('../connect-plaid')
        }
    }, [isSuccess, navigate])
    if (isLoading) return <Loader />
    return (
        <Tile title="Business Details">
            <StyledForm centered onSubmit={handleSubmit((data) => mutate(data))}>
                <BusinessDetailFormRow>
                    <InputWrapper>
                        <TextInput
                            showLabel
                            name="legal_name"
                            label="Legal Business Name*"
                            inputRef={() => register('legal_name', { required: true })}
                            placeholder="e.g. My Company, LLC."
                            errors={errors}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <TextInput
                            showLabel
                            name="tax_id"
                            label=" Tax Identification Number (TIN)*"
                            inputRef={() =>
                                register('tax_id', {
                                    required: true,
                                    validate: validateEIN,
                                })
                            }
                            placeholder="XX-XXXXXXX"
                            errors={errors}
                        />
                    </InputWrapper>
                </BusinessDetailFormRow>
                <BusinessDetailFormRow>
                    <InputWrapper>
                        <AddressInput
                            showLabel
                            name="address"
                            label="Headquarters Address*"
                            placeholder="e.g. 123 Main St, Los Angeles, CA"
                            triggerValidation={trigger}
                            register={register}
                            setValue={setValue}
                            errors={errors}
                            fullWidth
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <TextInput
                            showLabel
                            name="website"
                            label="Website*"
                            inputRef={() =>
                                register('website', {
                                    required: true,
                                    validate: (value) =>
                                        !isURL(value) ? 'Please enter a valid URL.' : true,
                                })
                            }
                            placeholder="e.g. https://getmarket.com"
                            errors={errors}
                        />
                    </InputWrapper>
                </BusinessDetailFormRow>
                <TermsWrap column>
                    <TermsGroup
                        centerAlign
                        style={{
                            border: errors.terms_acceptance ? '1px solid var(--color-red)' : 'none',
                        }}
                    >
                        <input
                            type="checkbox"
                            {...register('terms_acceptance', { required: true })}
                        />
                        <Text variant="p2">
                            I agree to the&nbsp;
                            <Link to="../../terms-and-conditions">Terms and Conditions</Link>
                        </Text>
                    </TermsGroup>
                    <TermsGroup
                        centerAlign
                        style={{
                            border: errors.officer_confirm ? '1px solid var(--color-red)' : 'none',
                        }}
                    >
                        <input
                            type="checkbox"
                            {...register('officer_confirm', { required: true })}
                        />
                        <Text variant="p2">
                            I am a controlling officer of my company (CEO, CFO, or other executive)
                        </Text>
                    </TermsGroup>
                </TermsWrap>
                <Flex centered>
                    <Button type="submit" loading={updating} size="medium">
                        Submit
                    </Button>
                </Flex>
            </StyledForm>
        </Tile>
    )
}

export default BusinessDetails
