import callApi from 'api/callApi'
import { ROOT_ROUTES, ORGANIZATION_ROUTES } from 'api/endpoints'

// eslint-disable-next-line import/prefer-default-export
export const postOrganization = (payload) =>
    callApi(ROOT_ROUTES.ORGANIZATIONS, 'post', {
        data: payload,
    })

export const patchBuyerOrganization = (updatesToOrg) =>
    callApi(ORGANIZATION_ROUTES.UPDATE_BUYER_ORGANIZATION, 'patch', {
        data: updatesToOrg,
    })

export const updateBuyerInvite = ({ values, buyerId }) =>
    callApi(ORGANIZATION_ROUTES.UPDATE_BUYER_INVITE(buyerId), 'patch', {
        data: values,
    })

export const getMiddeskVerification = (requestId) =>
    callApi(ORGANIZATION_ROUTES.GET_UNDERWRITING_CHECK(requestId))

export const getBuyers = () => callApi(ORGANIZATION_ROUTES.GET_BUYERS)

export const reinviteBuyer = (buyerId) => callApi(ORGANIZATION_ROUTES.REINVITE_BUYER(buyerId))

export const getOrganization = (orgId) => callApi(ORGANIZATION_ROUTES.ORGANIZATION_BY_ID(orgId))

export const getMemberships = (orgId) =>
    callApi(ORGANIZATION_ROUTES.ORGANIZATION_MEMBERSHIPS(orgId))

export const inviteBuyer = ({ payment_amount, payment_due_date, invoice_date, ...buyerData }) =>
    callApi(ORGANIZATION_ROUTES.INVITE_BUYER, 'post', {
        data: {
            ...buyerData,
            payment_amount: payment_amount ? payment_amount * 100 : null,
            payment_due_date: payment_due_date ? `${payment_due_date}T00:00` : null,
            invoice_date: invoice_date ? `${invoice_date}T00:00` : null,
        },
    })

export const inviteMember = (orgId, payload) =>
    callApi(ORGANIZATION_ROUTES.INVITE_MEMBER(orgId), 'post', {
        data: payload,
    })

export const patchMembership = (orgId, memId, payload) =>
    callApi(ORGANIZATION_ROUTES.MEMBERSHIP_BY_ID(orgId, memId), 'patch', {
        data: payload,
    })

export const getRoles = (orgId) => callApi(ORGANIZATION_ROUTES.GET_ROLES(orgId))
