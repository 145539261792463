import { useState } from 'react'
import styled from '@emotion/styled'
import { AnimatePresence, motion } from 'framer-motion'
import queryClient from 'api/query'
import { NavLink } from 'react-router-dom'
import useAvailableRoutes from 'common/hooks/useAvailableRoutes'
import { TOKEN_STORAGE_KEY } from 'common/constants'
import logOutIcon from 'assets/svg/icons/logout.svg'
import Logo from './Logo'
import NavItem from './NavItem'
import Text from './Text'
import Flex from './Flex'

const StickyWrapper = styled.div`
    z-index: 12;
    height: 100%;
    position: absolute;
`

const LogoWrapper = styled(motion.div)`
    overflow: hidden;
    margin-left: 17px;
    min-width: 37px;
`

const PositionedLogo = styled(Logo)`
    margin-top: 32px;
    object-fit: cover;
`

const SidebarWrap = styled(motion.div)`
    position: sticky;
    width: 80px;
    height: 100vh;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--color-background-light);
    overflow: hidden;
`

const NavItems = styled.nav`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
`

const LogOutLink = styled(NavLink)`
    display: flex;
    padding: 20px;
    position: relative;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    font-size: 24px;
    background: transparent;
    color: var(--color-black);
    letter-spacing: 1px;
    margin-bottom: 20px;
    &:hover {
        background: var(--color-snow-white);
    }
    &.active {
        background: var(--color-snow-white);
    }
`

const SidebarIcon = styled.img`
    width: 22px;
    height: 22px;
    object-fit: contain;
    margin-left: 9px;
`

const NavItemText = styled(Text)`
    margin-left: 10px;
`

const Sidebar = () => {
    const routes = useAvailableRoutes()
    const [hovering, setHovering] = useState(false)
    const [imageWidth, setImageWidth] = useState(27)
    const [navItemSelected, setNavItemSelected] = useState('')
    return (
        <StickyWrapper>
            <AnimatePresence>
                <SidebarWrap
                    whileHover={{ width: '250px' }}
                    onHoverStart={() => {
                        setImageWidth(160)
                        setHovering(true)
                    }}
                    onHoverEnd={() => {
                        setImageWidth(37)
                        setNavItemSelected('')
                        setHovering(false)
                    }}
                >
                    <Flex column>
                        <LogoWrapper initial={{ width: 37 }} animate={{ width: imageWidth }}>
                            <PositionedLogo width="150px" height="35px" link />
                        </LogoWrapper>
                        <NavItems>
                            {routes.map((route) => {
                                return (
                                    route.showInNav && (
                                        <NavItem
                                            key={route.path}
                                            navItem={route}
                                            selected={navItemSelected === route.path}
                                            setSelected={() =>
                                                setNavItemSelected(
                                                    navItemSelected === route.path ? '' : route.path
                                                )
                                            }
                                            expanded={hovering}
                                        />
                                    )
                                )
                            })}
                        </NavItems>
                    </Flex>
                    <LogOutLink
                        to="/login"
                        onClick={() => {
                            localStorage.clear(TOKEN_STORAGE_KEY)
                            queryClient.setQueryData(['currentUser'], null)
                            queryClient.setQueryData(['fetchPaymentAgreements'], [])
                        }}
                    >
                        <SidebarIcon src={logOutIcon} alt="Logout" expanded={hovering} />
                        {hovering && (
                            <NavItemText variant="p2" bold>
                                Logout
                            </NavItemText>
                        )}
                    </LogOutLink>
                </SidebarWrap>
            </AnimatePresence>
        </StickyWrapper>
    )
}

export default Sidebar
