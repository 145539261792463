import callApi from 'api/callApi'
import { PLAID_INTEGRATION_ROUTES, ROOT_ROUTES } from 'api/endpoints'

export const fetchPlaidIntegrations = () => callApi(ROOT_ROUTES.PLAID_INTEGRATIONS)

export const createPlaidIntegration = (plaidData) =>
    callApi(PLAID_INTEGRATION_ROUTES.CREATE_INTEGRATION, 'post', { data: plaidData })

export const fetchLinkToken = () =>
    callApi(PLAID_INTEGRATION_ROUTES.LINK_TOKEN, 'post', { data: {} })

export const setPrimaryPaymentMethod = (intId) =>
    callApi(PLAID_INTEGRATION_ROUTES.SET_PRIMARY(intId))
