import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { getAvailableRoutes } from 'routing/navItems'
import { fetchCurrentUser } from 'user/api'

const useAvailableRoutes = () => {
    const { data } = useQuery({
        queryKey: ['currentUser'],
        queryFn: fetchCurrentUser,
        retry: false,
        useErrorBoundary: false,
        suspense: true,
    })
    const routes = useMemo(() => getAvailableRoutes(data?.data?.organization), [data])
    return routes
}

export default useAvailableRoutes
