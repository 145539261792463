import ReactModal from 'react-modal'
import PropTypes from 'prop-types'
import FadeIn from './FadeIn'

const defaultStyles = {
    overlay: {
        zIndex: 15,
        boxSizing: 'border-box',
        background: 'var(--color-black-semi-opaque)',
    },
    content: {
        padding: 0,
        boxSizing: 'border-box',
        border: 'none',
        borderRadius: '12px',
        background: 'var(--color-white)',
        boxShadow: '0px 4px 26px var(--color-black-semi-opaque)',
        opacity: 1,
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
    },
}

const Modal = ({ children, onClose, contentStyle = {}, ...rest }) => {
    return (
        <ReactModal
            style={{
                overlay: defaultStyles.overlay,
                content: { ...defaultStyles.content, ...contentStyle },
            }}
            onAfterOpen={() => {
                document.body.style.overflow = 'hidden'
            }}
            onAfterClose={() => {
                document.body.style.overflow = 'unset'
                if (onClose) onClose()
            }}
            {...rest}
        >
            <FadeIn>{children}</FadeIn>
        </ReactModal>
    )
}

Modal.propTypes = {
    children: PropTypes.node,
    contentStyle: PropTypes.object,
    onClose: PropTypes.func,
}

export default Modal
