import PropTypes from 'prop-types'
import { iconData } from 'assets/svg/icons'
import styled from '@emotion/styled'

const StyledSvg = styled.svg`
    path {
        fill: ${({ color }) => color || 'currentColor'};
    }
`

const Icon = ({ name = '', color, width, height, ...rest }) => {
    const { multiPath, src, path, width: defaultWidth, height: defaultHeight } = iconData[name]
    const computedHeight = height || defaultHeight
    const computedWidth = width || defaultWidth

    if (!path && !multiPath) {
        throw new Error('invalid icon name entered')
    }

    if (multiPath) {
        return <img src={src} alt={`${name} icon`} {...rest} />
    }

    return (
        <StyledSvg
            color={color}
            xmlns="http://www.w3.org/2000/svg"
            width={computedWidth}
            height={computedHeight}
            viewBox={`0 0 ${defaultWidth} ${defaultHeight}`}
            {...rest}
        >
            <path d={path} />
        </StyledSvg>
    )
}

Icon.propTypes = {
    name: PropTypes.string.isRequired,
    color: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
}

export default Icon
