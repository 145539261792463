import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { usePlaidLink } from 'react-plaid-link'
import styled from '@emotion/styled'
import plaidLogo from 'assets/svg/logo-plaid.svg'
import { errorToast } from 'common/toasts'
import Button from 'common/components/Button'
import { useMutation, useQuery } from '@tanstack/react-query'
import { fetchCurrentUser } from 'user/api'
import { ONBOARDING, PLAID_CONTEXT, PLAID_LINK_MODE, PLAID_LINK_TOKEN } from 'common/localStorage'
import Text from 'common/components/Text'
import Tile from 'common/components/Tile'
import { createPlaidIntegration, fetchLinkToken } from 'plaidIntegrations/api'
import { getAllPaymentRequests } from 'paymentRequests/api'
import { orderBy } from 'lodash'
import queryClient from 'api/query'
import ProcessingScreen from 'common/components/ProcessingScreen'

const PlaidButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        background: var(--color-medium-dark-gray);
    }
`

const PlaidLogo = styled.img`
    width: 19px;
    height: 19px;
    margin-right: 7px;
`

const ConnectPlaid = () => {
    const navigate = useNavigate()
    const [paymentRequestId, setPaymentRequestId] = useState(null)
    const { data } = useQuery({
        queryKey: ['plaidLinkToken'],
        queryFn: fetchLinkToken,
    })
    const { data: userData, isLoading } = useQuery({
        queryKey: ['currentUser'],
        queryFn: fetchCurrentUser,
    })
    const { isLoading: fetchingPaymentRequests } = useQuery({
        queryKey: ['fetchPaymentRequests'],
        queryFn: getAllPaymentRequests,
        onSuccess: (res) => {
            if (!paymentRequestId && res.data.length) {
                const initialPaymentRequest = orderBy(res.data, 'created_at', ['desc'])[0]
                setPaymentRequestId(initialPaymentRequest.id)
            }
        },
    })
    const {
        mutate,
        isLoading: creating,
        isSuccess,
    } = useMutation({
        mutationKey: ['createPlaidIntegration'],
        mutationFn: createPlaidIntegration,
        onError: () => {
            errorToast()
        },
    })
    const onSuccess = useCallback(
        async (publicToken, meta) => {
            if (meta.account.subtype !== 'checking' && meta.account.subtype !== 'savings') {
                errorToast(
                    'Please select a checking or savings account for your initial account. You may add other accounts later in the Settings view.'
                )
                return
            }
            mutate({
                public_token: publicToken,
                meta,
                organization_id: userData?.data?.organization?.id,
                payment_request_id: paymentRequestId,
            })
        },
        [mutate, paymentRequestId, userData]
    )
    const { open, ready } = usePlaidLink({ token: data?.data?.link_token, onSuccess })
    const setStorageItems = useCallback((linkToken, plaidMode) => {
        localStorage.setItem(PLAID_LINK_TOKEN, linkToken)
        localStorage.setItem(PLAID_LINK_MODE, plaidMode)
        localStorage.setItem(PLAID_CONTEXT, ONBOARDING)
    }, [])

    useEffect(() => {
        const onboardingRequestId = localStorage.getItem('OnboardingPRID')
        if (onboardingRequestId) {
            setPaymentRequestId(onboardingRequestId)
        }
    }, [setPaymentRequestId])

    useEffect(() => {
        if (isSuccess) {
            queryClient.invalidateQueries({ queryKey: ['plaidIntegrationList'] })
            navigate(`../../new-request/${paymentRequestId}/underwriting-check`)
        }
    }, [isSuccess, navigate, paymentRequestId])

    if (creating || isLoading || fetchingPaymentRequests) {
        return <ProcessingScreen />
    }

    return (
        <Tile title="Connect your bank account">
            <Text variant="p1" m="0 0 50px 0" centered>
                We&apos;ll use this as your primary payment account for Market and to qualify you
                for early
                <br />
                pay discounts and extended terms.
            </Text>
            <PlaidButton
                onClick={() => {
                    setStorageItems(data?.data?.link_token)
                    open()
                }}
                loading={!ready}
                variant="outline"
                size="large"
            >
                <PlaidLogo src={plaidLogo} />
                Plaid
            </PlaidButton>
            <Text m="50px 0 50px 0" variant="d">
                *We&apos;re serious about your data. We have bank level security and use 256
                encryption standards.
            </Text>
        </Tile>
    )
}

export default ConnectPlaid
