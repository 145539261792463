import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Flex from './Flex'

const OnboardingTileWrapper = styled(Flex)`
    background-color: var(--color-white);
    box-shadow: 0px 50px 50px -40px var(--color-black-one-quarter-opacity);
    border-radius: 20px;
    max-width: 1000px;
    height: ${({ tileHeight }) => tileHeight};
    overflow: hidden;
    align-items: stretch;
    overflow: hidden;
    margin: auto;
`

const OnboardingTileText = styled(Flex)`
    padding: 50px;
    justify-content: space-between;
    width: ${({ hasImage }) => (hasImage ? 60 : 100)}%;
`

const OnboardingTileMainImage = styled.img`
    width: ${({ imageWidth }) => imageWidth};
    height: 100%;
    object-fit: cover;
`

const OnboardingTile = ({ children, imageUrl, tileHeight = '100%', imageWidth = '50%' }) => {
    return (
        <OnboardingTileWrapper tileHeight={tileHeight}>
            <OnboardingTileText hasImage={!!imageUrl} column>
                {children}
            </OnboardingTileText>
            {imageUrl && (
                <OnboardingTileMainImage
                    src={imageUrl}
                    alt="Onboarding graphic"
                    imageWidth={imageWidth}
                />
            )}
        </OnboardingTileWrapper>
    )
}

OnboardingTile.propTypes = {
    children: PropTypes.node,
    imageUrl: PropTypes.string,
    imageWidth: PropTypes.string,
    tileHeight: PropTypes.string,
}

export default OnboardingTile
