import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { capitalize, isEmpty } from 'lodash'
import { useMemo } from 'react'
import Flex from './Flex'
import Text from './Text'

const StyledInput = styled.input`
    all: unset;
    box-sizing: border-box;
    padding: 12px 3px 12px 20px;
    border-radius: 6px;
    border: 1px solid transparent;
    width: 100%;
    position: absolute;
    transition: border-color 0.2s ease, background-color 0.2s ease, color 0.2s ease;
    :hover {
        background-color: var(--color-snow-white);
        border-color: var(--color-medium-light-gray);
    }
    :focus {
        border-color: var(--color-blue);
        background-color: var(--color-snow-white);
    }
    :disabled {
        border-color: transparent;
        color: var(--color-dark-gray);
        background-color: var(--color-snow-white);
    }
    ::placeholder {
        color: var(--color-medium-light-gray);
        font-size: 16px;
    }
    ${({ hasError }) =>
        hasError &&
        `border-color: var(--color-red);
        background-color: var(--color-light-red);
        :hover {
            border-color: var(--color-red);
            background-color: var(--color-light-red);
        }
        :focus {
            border-color: var(--color-red);
            background-color: var(--color-light-red);
        }`}
`

const CurrencyDecorator = styled(Text)`
    position: absolute;
    top: 13.5px;
    left: 10px;
    z-index: 10;
`

const MoneyInput = styled(Flex)`
    width: 100%;
    height: 45px;
    text-align: left;
    background-color: var(--color-snow-white);
    color: var(--color-black);
    align-items: baseline;
    border-radius: 6px;
    position: relative;
    & > div {
        color: ${({ hasError, noValue }) => {
            if (hasError && !noValue) return 'var(--color-red)'
            if (noValue) return 'var(--color-medium-light-gray)'
            return 'var(--color-black)'
        }};
    }
    ${({ hasError }) => hasError && `color: var(--color-red);`}
`

const ColorHolder = styled(Flex)`
    width: 100%;
    ${({ errorPotential, hasError }) => errorPotential && !hasError && 'padding-bottom: 17px'};
    overflow: visible:
`

const ErrorMessage = styled.div`
    color: var(--color-red);
    font-size: 10px;
    font-style: italic;
`

const Label = styled.label`
    font-size: 14px;
    font-weight: 200;
    color: var(--color-dark-gray);
`

const CurrencyInput = ({ inputRef, label, className, errors, errorMessage, watch, ...rest }) => {
    const inputName = useMemo(() => inputRef().name, [inputRef])
    const hasError = errors && !isEmpty(errors[inputName])
    const eMessage =
        errorMessage ||
        (hasError && errors[inputName].message) ||
        `${capitalize(label ?? inputName)} is a required field.`
    const currValue = watch(inputName)
    const inputProps = {
        type: 'number',
        step: '0.01',
    }
    return (
        <ColorHolder
            className={className}
            errorPotential={errors}
            hasError={hasError}
            gap="5"
            column
        >
            {label && <Label htmlFor={inputName}>{label}</Label>}
            <MoneyInput noValue={!currValue} hasError={hasError} centerAlign>
                <CurrencyDecorator>$</CurrencyDecorator>
                <StyledInput hasError={hasError} {...inputProps} {...inputRef()} {...rest} />
            </MoneyInput>
            {hasError && <ErrorMessage>{eMessage}</ErrorMessage>}
        </ColorHolder>
    )
}

CurrencyInput.propTypes = {
    inputRef: PropTypes.func,
    label: PropTypes.string,
    className: PropTypes.string,
    errors: PropTypes.object,
    errorMessage: PropTypes.string,
    watch: PropTypes.func,
}

export default CurrencyInput
