export const TRUE = 'true'
export const FALSE = 'false'
export const SHOW_MEMBER_INVITE = 'showMemberInvite'
export const SHOW_CREDIT_PROMPT = 'showCreditPrompt'
export const PLAID_LINK_TOKEN = 'plaidLinkToken'
export const PLAID_CONTEXT = 'plaidContext'
export const PLAID_LINK_MODE = 'plaidLinkMode'
export const PLAID_UPDATE_ID = 'plaidUpdateId'
export const ONBOARDING = 'onboarding'
export const SETTINGS = 'settings'

export const startOnboarding = () => {
    // localStorage.setItem(SHOW_MEMBER_INVITE, TRUE)
    localStorage.setItem(SHOW_CREDIT_PROMPT, TRUE)
}

export const checkOnboardingState = () => ({
    showMemberInvite: localStorage.getItem(SHOW_MEMBER_INVITE) === TRUE,
    showCreditPrompt: localStorage.getItem(SHOW_CREDIT_PROMPT) === TRUE,
})
