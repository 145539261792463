import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import bankIcon from 'assets/svg/bank-illo.svg'
import greenCheckIcon from 'assets/svg/green-check.svg'
import Button from 'common/components/Button'
import Flex from 'common/components/Flex'
import Text from 'common/components/Text'
import { useCallback, useMemo } from 'react'
import { useMutation } from '@tanstack/react-query'
import { setPrimaryPaymentMethod } from 'plaidIntegrations/api'
import queryClient from 'api/query'

const PlaidIntegrationWrapper = styled(Flex)`
    border: 1px solid var(--color-medium-light-gray);
    border-radius: 7px;
    height: 64px;

    & > div {
        width: 30%;
    }
`

const BankName = styled(Flex)`
    width: 158px;
`

const BankIcon = styled.img`
    width: 20px;
    height: 20px;
`

const SetAsPrimaryButton = styled(Button)`
    background-color: var(--color-green);
`

const PrimaryAccount = styled(Flex)`
    width: 158px;
`

const GreenText = styled(Text)`
    color: var(--color-green);
    font-size: 14px;
    text-align: right;
`

const GreenCheck = styled.img`
    width: 20px;
    height: 20px;
    object-fit: contain;
`

const PlaidIntegration = ({ plaidIntegration }) => {
    const {
        id,
        meta: { institution, account },
        payment_method,
    } = useMemo(() => plaidIntegration ?? {}, [plaidIntegration])
    const { mutate, isLoading } = useMutation({
        mutationKey: ['setPrimaryPaymentMethod'],
        mutationFn: setPrimaryPaymentMethod,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['plaidIntegrationList'] })
        },
    })
    const handleClick = useCallback(() => {
        mutate(id)
    }, [mutate, id])
    return (
        <PlaidIntegrationWrapper p="10px 40px" justify="space-between" centerAlign>
            <BankName gap="10" centerAlign>
                <BankIcon src={bankIcon} alt="Bank icon" />
                <Text variant="h3">{institution.name}</Text>
            </BankName>
            <Text variant="h3" centered secondary>
                {account.name}
            </Text>
            <Flex justify="flex-end">
                {payment_method?.primary ? (
                    <PrimaryAccount gap="10" centered>
                        <GreenCheck src={greenCheckIcon} alt="Green check" />
                        <GreenText bold>Primary Account</GreenText>
                    </PrimaryAccount>
                ) : (
                    <SetAsPrimaryButton
                        size="small"
                        onClick={handleClick}
                        disabled={account.type !== 'depository'}
                        loading={isLoading}
                    >
                        Set As Primary
                    </SetAsPrimaryButton>
                )}
            </Flex>
        </PlaidIntegrationWrapper>
    )
}

PlaidIntegration.propTypes = {
    plaidIntegration: PropTypes.object,
}

export default PlaidIntegration
