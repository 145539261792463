import styled from '@emotion/styled'
import Card from './Card'

const OnboardingCard = styled(Card)`
    box-shadow: 0px 50px 50px -40px var(--color-black-one-quarter-opacity);
    border-radius: 16px;
    max-width: ${({ maxWidth }) => maxWidth};
    margin: 0 auto;
`
export default OnboardingCard
