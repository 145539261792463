import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import calendar from 'dayjs/plugin/calendar'
import updateLocale from 'dayjs/plugin/updateLocale'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import { capitalize, isEmpty } from 'lodash'
import toast from 'react-hot-toast'
import { isStrongPassword } from 'validator'
import { LOADING_STATES, PW_STRENGTH_ERROR } from './constants'

dayjs.extend(advancedFormat)
dayjs.extend(utc)
dayjs.extend(updateLocale)
dayjs.extend(timezone)
dayjs.tz.setDefault('America/Los_Angeles')
dayjs.extend(isBetween)
dayjs.extend(calendar)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(customParseFormat)

dayjs.updateLocale('en', {
    calendar: {
        lastDay: '[Yesterday at] h:mm A',
        sameDay: '[Today at] h:mm A',
        nextDay: '[Tomorrow at] h:mm A',
        lastWeek: '[last] dddd [at] h:mm A',
        nextWeek: 'dddd [at] h:mm A',
        sameElse: 'MMMM Do, YYYY',
    },
})

// Guess the timezone based on user agent.
// add the necessary pos/neg minutes to get a local date.
export const getLocalDate = (datetime) => {
    const guess = dayjs.tz.guess()
    const dateObj = dayjs.tz(datetime, guess)
    return dateObj.add(dateObj.$offset, 'minute')
}

export const sortByNew = (a, b) => dayjs(b) - dayjs(a)

export const randIntFromRange = ({ min = 0, max }) => Math.floor(Math.random() * (max - min) + min)

export const updateItem = (state, action) => ({
    entities: state.entities.map((item) => {
        if (item.id !== action.payload.data.id) return item
        return {
            ...item,
            ...action.payload.data,
        }
    }),
    loading: LOADING_STATES.IDLE,
})

export const validateEIN = (input) => {
    const patternEIN = /^[0-9]\d?-\d{7}$/
    return !patternEIN.test(input) ? 'Please enter a valid EIN (eg. 11-1111111)' : true
}

export const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement('canvas')
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    const ctx = canvas.getContext('2d')
    const pixelRatio = window.devicePixelRatio
    canvas.width = crop.width * pixelRatio
    canvas.height = crop.height * pixelRatio
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
    ctx.imageSmoothingQuality = 'high'

    ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
    )

    return new Promise((resolve) => {
        canvas.toBlob(
            (blob) => {
                blob.name = fileName
                resolve(blob)
            },
            'image/jpeg',
            1
        )
    })
}

export const copyToClipboard = (str) => {
    const el = document.createElement('textarea')
    el.value = str
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
}

export const checkUrlProtocol = (url) => {
    if (!url) return null
    const protocolCheckRegex = new RegExp('^(http|https)://', 'i')
    if (protocolCheckRegex.test(url.toLowerCase())) {
        return url
    }
    return `https://${url}`
}

export const getCurrencyString = (num) =>
    num.toLocaleString('en-US', { style: 'currency', currency: 'USD' })

export const formatCurrency = (num) => getCurrencyString(num ? num / 10 ** 2 : 0)

export const removeStartingString = (str, toRemove) => {
    if (str.slice(0, toRemove.length) === toRemove) {
        return str.slice(toRemove.length)
    }
    return str
}

const isUserDiscussionPost = ({ group, content, created_at }) =>
    !isEmpty(group) && isEmpty(content) ? created_at : content.published_at

export const sortByRecency = (postA, postB) =>
    isUserDiscussionPost(postB).localeCompare(isUserDiscussionPost(postA))

export const enumKeyToString = (enumKey) => {
    return enumKey
        .split('_')
        .map((word) => capitalize(word))
        .join(' ')
}

const pwOptions = {
    minLength: 10,
    minLowercase: 1,
    minUppercase: 1,
    minNumbers: 1,
    minSymbols: 0,
    returnScore: false,
}

export const validateStrongPW = (input) => {
    if (!isStrongPassword(input, pwOptions)) {
        toast.error(PW_STRENGTH_ERROR, { id: 'pw-strength-toast' })
        return 'Invalid password'
    }
    return true
}

export const downloadBlob = (blob, filename) => {
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.style.display = 'none'
    a.href = url
    a.download = filename
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    window.URL.revokeObjectURL(url)
}

export const getFilenameFromHeaders = (headers) =>
    headers['content-disposition'].split(';')[1].split('=')[1].split('"')[1]
