import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Flex from 'common/components/Flex'

const StatusCellWrapper = styled(Flex)`
    width: ${({ tableCell }) => (tableCell ? 106 : 80)}px;
    font-size: ${({ tableCell }) => (tableCell ? 14 : 12)}px;
    padding: 5px 0;
    border-radius: ${({ tableCell }) => (tableCell ? 6 : 16)}px;
    background-color: ${({ statusColor }) => statusColor};
    & > * {
        text-align: center;
    }
`

const StatusCell = ({ value, statusColor, tableCell = false }) => (
    <Flex centered>
        <StatusCellWrapper statusColor={statusColor} tableCell={tableCell} centered>
            {value}
        </StatusCellWrapper>
    </Flex>
)

StatusCell.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    statusColor: PropTypes.string,
    tableCell: PropTypes.bool,
}

export default StatusCell
