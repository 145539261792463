import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { useMutation } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { requestPayment } from 'paymentRequests/api'
import queryClient from 'api/query'
import closeIcon from 'assets/svg/icons/circleX.svg'
import { errorToast } from 'common/toasts'
import Modal from 'common/components/Modal'
import Text from 'common/components/Text'
import CurrencyInput from 'common/components/CurrencyInput'
import DateInput from 'common/components/DateInput'
import TextArea from 'common/components/TextArea'
import Button from 'common/components/Button'
import Flex from 'common/components/Flex'
import PaymentDueDateFields from './PaymentDueDateFields'

const ModalWrapper = styled(Flex)`
    padding: 20px;
    position: relative;

    & > form {
        margin: 30px 0 10px;
        width: 400px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    textarea {
        margin-bottom: 30px;
    }
`

const CloseIcon = styled.img`
    position: absolute;
    top: 20px;
    right: 20px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    opacity: 1;
    transition: all 0.3s ease;
    :hover {
        opacity: 0.7;
    }
`

const customerNotOnboardedError = 'Customer has not been onboarded yet.'

const RequestPaymentModal = ({ isOpen, onRequestClose, refresh }) => {
    const { buyerId } = useParams()
    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors },
    } = useForm({ reValidateMode: 'onSubmit' })
    const { mutate, isLoading } = useMutation({
        mutationKey: ['createPaymentRequest'],
        mutationFn: requestPayment,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [`buyer${buyerId}PaymentRequests`] })
            queryClient.invalidateQueries({ queryKey: [`listPaymentRequests`] })
            onRequestClose()
            refresh()
        },
        onError: (data) => {
            errorToast(
                data.detail === customerNotOnboardedError
                    ? 'Customer has not onboarded yet, please wait for them to complete the initial payment request before requesting another. If this is an issue please reach out to support@getmarket.com'
                    : 'An error occurred when creating the payment request.'
            )
        },
    })
    const onSubmit = useCallback((data) => mutate({ ...data, buyerId }), [mutate, buyerId])
    const currentInvoiceDate = watch('invoice_date')
    useEffect(() => {
        if (isOpen) {
            reset()
        }
    }, [isOpen, reset])

    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
            <ModalWrapper column>
                <CloseIcon src={closeIcon} alt="Close button" onClick={onRequestClose} />
                <Text variant="h2">Request Payment</Text>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <CurrencyInput
                        label="Payment Amount"
                        inputRef={() =>
                            register('payment_amount', {
                                required: true,
                                validate: (value) => value >= 20 || 'Amount must be more than $20',
                            })
                        }
                        placeholder="0.00"
                        watch={watch}
                        errors={errors}
                    />
                    <DateInput
                        label="Invoice Date"
                        inputRef={() =>
                            register('invoice_date', {
                                required: true,
                                validate: (value) =>
                                    dayjs().isSameOrBefore(value) ||
                                    'Invoice date must not be in the past.',
                            })
                        }
                        watch={watch}
                        errors={errors}
                    />
                    <PaymentDueDateFields invoiceDate={currentInvoiceDate} />
                    <TextArea inputRef={() => register('memo')} label="Memo (optional)" />
                    <Button size="full" type="submit" loading={isLoading}>
                        Request
                    </Button>
                </form>
            </ModalWrapper>
        </Modal>
    )
}

RequestPaymentModal.propTypes = {
    isOpen: PropTypes.bool,
    onRequestClose: PropTypes.func,
    refresh: PropTypes.func,
}

export default RequestPaymentModal
