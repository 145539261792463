import { POST_PAGE_SIZE } from 'common/constants'

export const ROOT_ROUTES = {
    USERS: '/users',
    POSTS: '/posts',
    GROUPS: '/groups',
    BUSINESSES: '/businesses',
    CREDIT_TERMS: '/credit-terms',
    INTEGRATIONS: (integrationType) => {
        let route = '/integrations'
        if (integrationType) route += `?integration_type=${integrationType}`
        return route
    },
    PLAID_INTEGRATIONS: '/plaid-integrations',
    IDENTITY_VERIFICATION: '/identity-verification',
    INTEGRATION_STATUS: '/integration-status',
    ORGANIZATIONS: '/organizations',
    CARDS: '/cards',
    TRANSACTION_EXPORT: '/transaction-export',
    PAYMENT_REQUESTS: '/payment-requests',
    PAYMENT_AGREEMENTS: '/payment-agreements',
    PAYMENT_TERMS_OPTIONS: '/payment-terms-options',
    BUYER: '/buyer',
    VENDOR_ROUTES: '/vendor',
}

export const BUYER_ROUTES = {
    GET_PAYMENT_AGREEMENTS: `${ROOT_ROUTES.BUYER}/agreements`,
    CREATE_PAYMENT_AGREEMENT: `${ROOT_ROUTES.BUYER}/agreement`,
    GET_REQUESTS: `${ROOT_ROUTES.BUYER}/requests`,
    GET_PAYMENT_TERM_OPTIONS: `${ROOT_ROUTES.PAYMENT_REQUESTS}/terms/all`,
}

export const USER_ROUTES = {
    ME: `${ROOT_ROUTES.USERS}/me`,
    SIGN_UP: `${ROOT_ROUTES.USERS}/sign-up`,
    PROFILE_PIC: `${ROOT_ROUTES.USERS}/me/picture`,
    PASSWORD: `${ROOT_ROUTES.USERS}/me/password`,
    ACCESS_TOKEN: `${ROOT_ROUTES.USERS}/access-token`,
    VERIFY_TOKEN: (token) => `${ROOT_ROUTES.USERS}/verify-token?token=${token}`,
    REQUEST_RESET: (email) => `${ROOT_ROUTES.USERS}/request-password-reset?email=${email}`,
    FETCH_USER_TRANSACTIONS: (limit, skip, startDate, endDate) => {
        let route = `${ROOT_ROUTES.USERS}/me/transactions?limit=${limit}&skip=${skip}`
        if (startDate && endDate) route += `&start_date=${startDate}&end_date=${endDate}`
        return route
    },
    REINVITE_ORG_MEMBER: (userId) => `${ROOT_ROUTES.USERS}/${userId}/reinvite`,
}

export const VENDOR_ROUTES = {
    FETCH_BUYERS: `${ROOT_ROUTES.USERS}/me`,
    SIGN_UP: `${ROOT_ROUTES.USERS}/sign-up`,
    PROFILE_PIC: `${ROOT_ROUTES.USERS}/me/picture`,
    PASSWORD: `${ROOT_ROUTES.USERS}/me/password`,
    ACCESS_TOKEN: `${ROOT_ROUTES.USERS}/access-token`,
    VERIFY_TOKEN: (token) => `${ROOT_ROUTES.USERS}/verify-token?token=${token}`,
    REQUEST_RESET: (email) => `${ROOT_ROUTES.USERS}/request-password-reset?email=${email}`,
    FETCH_USER_TRANSACTIONS: (limit, skip, startDate, endDate) => {
        let route = `${ROOT_ROUTES.USERS}/me/transactions?limit=${limit}&skip=${skip}`
        if (startDate && endDate) route += `&start_date=${startDate}&end_date=${endDate}`
        return route
    },
    REINVITE_ORG_MEMBER: (userId) => `${ROOT_ROUTES.USERS}/${userId}/reinvite`,
}

export const POST_ROUTES = {
    DISCOVER_FEED: (sort = 'new', limit = POST_PAGE_SIZE, skip = 0, trending = false) =>
        `${ROOT_ROUTES.POSTS}?sort_by=${sort}&limit=${limit}&skip=${skip}&trending=${trending}`,
    COMMENTS: (postId) => `${ROOT_ROUTES.POSTS}/${postId}/comments`,
    COMMENT_REPLY: (postId, commentId) =>
        `${ROOT_ROUTES.POSTS}/${postId}/comments/${commentId}/comments`,
}

export const PLAID_INTEGRATION_ROUTES = {
    CREATE_INTEGRATION: `${ROOT_ROUTES.PLAID_INTEGRATIONS}/plaid`,
    LINK_TOKEN: `${ROOT_ROUTES.PLAID_INTEGRATIONS}/plaid/create-link-token`,
    SET_PRIMARY: (intId) => `${ROOT_ROUTES.PLAID_INTEGRATIONS}/${intId}/primary`,
}

export const INTEGRATION_ROUTES = {
    PLAID_INTEGRATION: `${ROOT_ROUTES.INTEGRATIONS()}/plaid`,
    PLAID_TOKEN: `${ROOT_ROUTES.INTEGRATIONS()}/plaid/create-link-token`,
    INTEGRATION_STATUS_BY_INT_ID: (intId) => `${ROOT_ROUTES.INTEGRATION_STATUS}/${intId}`,
    INTEGRATION_BY_ID: (intId) => `${ROOT_ROUTES.INTEGRATIONS()}/${intId}`,
    QB_AUTH_URL: 'quickbooks/oauth',
    QB_INTEGRATION: 'quickbooks/integration',
    QB_ACCOUNTS: 'quickbooks/account',
    QB_VENDORS: 'quickbooks/vendor',
    QB_CUSTOMERS: 'quickbooks/customer',
    QB_MAPPING: 'quickbooks/mapping',
    QB_JOB: 'quickbooks/transactionjob',
    IM_CONFIG: (orgId) => `improvado/initialize/${orgId}`,
    IM_INTEGRATION: 'improvado/integration',
    CODAT_INTEGRATION: 'codat/integration',
    CODAT_ACCOUNTS: (intId) => `codat/integration/${intId}/accounts`,
    CODAT_DISCONNECT: (intId) => `codat/integration/${intId}/disconnect`,
    CODAT_RECONNECT: (intId) => `codat/integration/${intId}/reconnect-link`,
    RUTTER: 'rutter/integration',
}

export const ORGANIZATION_ROUTES = {
    GET_BUYERS: `${ROOT_ROUTES.ORGANIZATIONS}/buyers`,
    GET_UNDERWRITING_CHECK: (requestId) =>
        `${ROOT_ROUTES.ORGANIZATIONS}/underwriting-check?payment_request_id=${requestId}`,
    INVITE_BUYER: `${ROOT_ROUTES.ORGANIZATIONS}/buyers/invite`,
    UPDATE_BUYER_ORGANIZATION: `${ROOT_ROUTES.ORGANIZATIONS}/buyers`,
    UPDATE_BUYER_INVITE: (buyerId) =>
        `${ROOT_ROUTES.ORGANIZATIONS}/buyers/${buyerId}/update-invite`,
    REINVITE_BUYER: (buyerId) => `${ROOT_ROUTES.ORGANIZATIONS}/buyers/${buyerId}/reinvite`,
    ORGANIZATION_BY_ID: (orgId) => `${ROOT_ROUTES.ORGANIZATIONS}/${orgId}`,
    ORGANIZATION_MEMBERSHIPS: (orgId) => `${ROOT_ROUTES.ORGANIZATIONS}/${orgId}/memberships`,
    MEMBERSHIP_BY_ID: (orgId, memId) =>
        `${ROOT_ROUTES.ORGANIZATIONS}/${orgId}/memberships/${memId}`,
    INVITE_MEMBER: (orgId) => `${ROOT_ROUTES.ORGANIZATIONS}/${orgId}/invite`,
    GET_ROLES: (orgId) => `${ROOT_ROUTES.ORGANIZATIONS}/${orgId}/roles`,
    FETCH_ORG_TRANSACTIONS: (orgId, limit, skip, startDate, endDate) => {
        let route = `${ROOT_ROUTES.ORGANIZATIONS}/${orgId}/transactions/all?limit=${limit}&skip=${skip}`
        if (startDate && endDate) route += `&start_date=${startDate}&end_date=${endDate}`
        return route
    },
    FETCH_CARD_TRANSACTIONS: (orgId, cardId, limit, skip, startDate, endDate) => {
        let route = `${ROOT_ROUTES.ORGANIZATIONS}/${orgId}/transactions/card/${cardId}?limit=${limit}&skip=${skip}`
        if (startDate && endDate) route += `&start_date=${startDate}&end_date=${endDate}`
        return route
    },
    FETCH_USER_TRANSACTIONS: (orgId, userId, limit, skip, startDate, endDate) => {
        let route = `${ROOT_ROUTES.ORGANIZATIONS}/${orgId}/transactions/user/${userId}?limit=${limit}&skip=${skip}`
        if (startDate && endDate) route += `&start_date=${startDate}&end_date=${endDate}`
        return route
    },
    FETCH_ORG_CHARGES: (orgId) => `${ROOT_ROUTES.ORGANIZATIONS}/${orgId}/charges`,
    ORGANIZATION_BALANCE: (orgId) => `${ROOT_ROUTES.ORGANIZATIONS}/${orgId}/balance`,
}

export const PAYMENT_REQUEST_ROUTES = {
    GET_PAYMENT_REQUESTS_BY_BUYER: (buyerId) => `${ROOT_ROUTES.PAYMENT_REQUESTS}/buyer/${buyerId}`,
    CREATE_PAYMENT_REQUEST_FOR_BUYER: (buyerId) =>
        `${ROOT_ROUTES.PAYMENT_REQUESTS}/buyer/${buyerId}`,
    EXPORT_ALL_PAYMENT_REQUESTS: `${ROOT_ROUTES.PAYMENT_REQUESTS}/export/all`,
    EXPORT_PAYMENT_REQUESTS_BY_BUYER: (buyerId) =>
        `${ROOT_ROUTES.PAYMENT_REQUESTS}/buyer/${buyerId}/export`,
    DOWNLOAD_INVOICE_PDF: (prId) => `${ROOT_ROUTES.PAYMENT_REQUESTS}/${prId}/pdf`,
}

export const PAYMENT_AGREEMENT_ROUTES = {
    CREATE_PAYMENT_AGREEMENT: (paymentRequestId) =>
        `${ROOT_ROUTES.BUYER_ROUTES}${ROOT_ROUTES.PAYMENT_AGREEMENTS}/${paymentRequestId}`,
}

export const FINANCE_ROUTES = {
    MONTHLY_STATEMENTS_BY_ORG: (orgId, limit, skip) =>
        `/monthly-statements/organization/${orgId}?limit=${limit}&skip=${skip}`,
    PREPAYMENT: 'charges/pre-payment',
    APPLY_FOR_CREDIT: `${ROOT_ROUTES.CREDIT_TERMS}/apply`,
}

export const TRANSACTION_EXPORT_ROUTES = {
    TRANSACTION_EXPORT_BY_CARD_ID: (cardId) => `${ROOT_ROUTES.TRANSACTION_EXPORT}/${cardId}`,
    TRANSACTION_EXPORT_MULTI_CARD: `${ROOT_ROUTES.TRANSACTION_EXPORT}/multi`,
}

export const CARD_ROUTES = {
    CARD_BY_ID: (id) => `${ROOT_ROUTES.CARDS}/${id}`,
    MY_CARDS: (expanded = true) => `${USER_ROUTES.ME}/cards?expanded=${expanded}`,
    EXPANDED_CARD_DETAILS: (id) => `${ROOT_ROUTES.CARDS}/${id}/more`,
    CARD_STATUS: (id, operation) => `${ROOT_ROUTES.CARDS}/${id}/${operation}`,
    CARD_LIMITS: (id) => `${ROOT_ROUTES.CARDS}/${id}/limits`,
}
