import styled from '@emotion/styled'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router-dom'
import Flex from 'common/components/Flex'
import Loader from 'common/components/Loader'
import PageSection from 'common/components/PageSection'
import Table from 'common/table/Table'
import { formatCurrency } from 'common/util'
import dayjs from 'dayjs'
import { exportAllPaymentRequests, getAllPaymentRequests } from 'paymentRequests/api'
import userIcon from 'assets/svg/user.svg'
import rightIcon from 'assets/svg/right.svg'
import exportIcon from 'assets/svg/export.svg'
import Text from 'common/components/Text'
import Button from 'common/components/Button'
import { fetchCurrentUser } from 'user/api'
import { useCallback } from 'react'
import StatusCell from 'common/table/StatusCell'
import { paymentRequestStatusMap } from 'common/statusMaps'
import VendorRequestDetailsModal from 'paymentRequests/components/VendorRequestDetailsModal'

const UserIcon = styled.img`
    width: 30px;
    height: 30px;
`

const RightIcon = styled.img`
    width: 16px;
    height: 16px;
`

const ExportIcon = styled.img`
    width: 16px;
    height: 16px;
`

const BoldText = styled.span`
    font-weight: 700;
`

const paymentRequestColumns = [
    {
        header: 'Customer Name',
        id: 'buyer_name',
        accessorFn: (row) => row.buyer.organization.name ?? row.buyer.organization.legal_name,
        cell: (row) => <BoldText>{row.getValue()}</BoldText>,
    },
    {
        header: 'Created On',
        accessorKey: 'created_at',
        id: 'created_at',
        cell: (row) => <span>{dayjs(row.getValue()).format('MM/DD/YYYY')}</span>,
        sortingFn: 'datetime',
    },
    {
        header: 'Due Date',
        accessorKey: 'payment_due_date',
        id: 'due_date',
        cell: (row) => <span>{dayjs(row.getValue()).format('MM/DD/YYYY')}</span>,
        sortingFn: 'datetime',
    },
    {
        header: 'Payment Status',
        accessorFn: (row) => {
            if (row.payment_request_status.length > 1) {
                return paymentRequestStatusMap[row.payment_request_status.at(-1)?.status].text
            }
            if (dayjs().isBefore(row.invoice_date)) return 'Scheduled'
            return 'Requested'
        },
        id: 'payment_status',
        sortDescFirst: true,
        cell: (row) => {
            const statusBackgroundColor =
                Object.values(paymentRequestStatusMap).find((s) => s.text === row.getValue())
                    ?.color ?? 'var(--color-light-purple)'
            return (
                <StatusCell value={row.getValue()} statusColor={statusBackgroundColor} tableCell />
            )
        },
        sortingFn: 'text',
    },
    {
        header: 'Amount',
        id: 'amount',
        accessorKey: 'payment_amount',
        cell: (row) => <BoldText>{formatCurrency(row.getValue())}</BoldText>,
        sortingFn: 'basic',
    },
    {
        header: ' ',
        accessorKey: 'id',
        cell: () => <RightIcon src={rightIcon} alt="Right arrow icon" />,
    },
]

const PaymentRequests = () => {
    const { paymentRequestId } = useParams()
    const navigate = useNavigate()
    const { data: currUserData, isLoading: userLoading } = useQuery({
        queryKey: ['currentUser'],
        queryFn: fetchCurrentUser,
    })
    const { data, isFetching } = useQuery({
        queryKey: ['listPaymentRequests'],
        queryFn: getAllPaymentRequests,
        staleTime: 10000,
    })
    const { isLoading: exporting, mutate } = useMutation({
        mutationKey: [`paymentRequestExport`],
        mutationFn: exportAllPaymentRequests,
    })
    const exportPaymentRequests = useCallback(
        () =>
            mutate(
                currUserData?.data?.organization?.legal_name ??
                    currUserData?.data?.organization?.name ??
                    ''
            ),
        [mutate, currUserData]
    )
    if (isFetching || userLoading) return <Loader />
    return (
        <>
            <PageSection
                title="Payment Requests"
                tableSection
                controls={
                    <Button
                        size="small"
                        variant="invert"
                        onClick={exportPaymentRequests}
                        loading={exporting}
                    >
                        <Flex gap="10" centered>
                            <ExportIcon src={exportIcon} alt="Export icon" />
                            Export CSV
                        </Flex>
                    </Button>
                }
            >
                <Table
                    initialSort={{ id: 'created_at', desc: true }}
                    data={data?.data ?? []}
                    columns={paymentRequestColumns}
                    onRowClick={(rowId) => navigate(rowId)}
                />
                {!data?.data?.length && (
                    <Flex gap="10" p="150px" column centered>
                        <UserIcon src={userIcon} alt="User icon" />
                        <Text variant="h2">Request payment from a buyer to get started</Text>
                        <Flex gap="10" centerAlign>
                            <Button
                                size="medium"
                                onClick={() => {
                                    navigate('../buyers')
                                }}
                            >
                                View Buyers
                            </Button>
                        </Flex>
                    </Flex>
                )}
            </PageSection>
            <VendorRequestDetailsModal
                isOpen={!!paymentRequestId}
                onRequestClose={() => navigate('../payment-requests')}
            />
        </>
    )
}

export default PaymentRequests
