import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import Flex from 'common/components/Flex'
import Text from 'common/components/Text'
import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { getBuyers } from 'organizations/api'
import { getAllPaymentRequests } from 'paymentRequests/api'
import Loader from 'common/components/Loader'
import Button from 'common/components/Button'
import userIcon from 'assets/svg/user.svg'
import rightIcon from 'assets/svg/right.svg'
import { useNavigate } from 'react-router-dom'
import PageSection from 'common/components/PageSection'
import Table from 'common/table/Table'
import { formatCurrency } from 'common/util'

const UserIcon = styled.img`
    width: 30px;
    height: 30px;
`

const RightIcon = styled.img`
    width: 16px;
    height: 16px;
`

const BoldText = styled.span`
    font-weight: 700;
`

const buyerColumns = [
    {
        header: 'Customer Name',
        id: 'buyer_name',
        accessorFn: (row) => row.organization.name ?? row.organization.legal_name,
        cell: (row) => <BoldText>{row.getValue()}</BoldText>,
        sortingFn: 'text',
    },
    {
        header: 'Customer Email',
        id: 'buyer_email',
        accessorFn: (row) => row.organization.created_by_user?.email,
        sortingFn: 'alphanumeric',
    },
    {
        header: 'Date Joined',
        id: 'created_at',
        accessorFn: (row) => row.organization.created_at,
        cell: (row) => <span>{dayjs(row.getValue()).format('MM/DD/YYYY')}</span>,
        sortingFn: 'datetime',
    },
    {
        header: 'Requests',
        id: 'numberOfRequests',
        accessorKey: 'numberOfRequests',
        sortingFn: 'basic',
    },
    {
        header: 'Amount Outstanding',
        accessorFn: (row) => formatCurrency(row.totalOwed),
        id: 'totalOwed',
        cell: (row) => <BoldText>{row.getValue()}</BoldText>,
        sortDescFirst: true,
        sortingFn: (rowA, rowB) => (rowA.original.totalOwed < rowB.original.totalOwed ? -1 : 1),
    },
    {
        header: ' ',
        accessorKey: 'id',
        cell: () => <RightIcon src={rightIcon} alt="Right arrow icon" />,
    },
]

const Buyers = () => {
    const navigate = useNavigate()
    const { data: buyers, isFetching: buyersLoading } = useQuery({
        queryKey: ['listBuyers'],
        queryFn: getBuyers,
        staleTime: 10000,
    })
    const { data: paymentRequests, isFetching: paymentRequestsLoading } = useQuery({
        queryKey: ['listPaymentRequests'],
        queryFn: getAllPaymentRequests,
        staleTime: 10000,
    })
    const injectedBuyersList = useMemo(
        () =>
            buyers?.data.map((buyer) => ({
                ...buyer,
                ...paymentRequests?.data.reduce(
                    (totals, current) => {
                        if (
                            current.buyer.id === buyer.id &&
                            current.payment_request_status.at(-1)?.status !== 'Payment Cleared'
                        ) {
                            totals.numberOfRequests += 1
                            totals.totalOwed += current.payment_amount
                        }
                        return totals
                    },
                    { numberOfRequests: 0, totalOwed: 0 }
                ),
            })),
        [buyers, paymentRequests]
    )
    if (buyersLoading || paymentRequestsLoading) return <Loader />
    return (
        <PageSection
            title="Current Customers"
            controls={
                buyers?.data.length ? (
                    <Button
                        size="small"
                        onClick={() => {
                            navigate('create')
                        }}
                    >
                        Create Buyer
                    </Button>
                ) : (
                    <></>
                )
            }
            tableSection
        >
            {buyers?.data.length ? (
                <Table
                    initialSort={{
                        id: 'created_at',
                        desc: true,
                    }}
                    data={injectedBuyersList}
                    columns={buyerColumns}
                    onRowClick={(rowId) => navigate(rowId)}
                />
            ) : (
                <Flex gap="10" p="20px" column centered>
                    <UserIcon src={userIcon} alt="User icon" />
                    <Text variant="h2">Create a new buyer to get started</Text>
                    <Flex gap="10" centerAlign>
                        <Button
                            size="medium"
                            onClick={() => {
                                navigate('create')
                            }}
                        >
                            Create Buyer
                        </Button>
                    </Flex>
                </Flex>
            )}
        </PageSection>
    )
}

export default Buyers
