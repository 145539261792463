import useAvailableRoutes from 'common/hooks/useAvailableRoutes'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import App from 'views/App'

const Router = () => {
    const routes = useAvailableRoutes()
    return (
        <BrowserRouter>
            <Routes>
                <Route path="*" element={<App />}>
                    {routes.map((route) =>
                        route.subRoutes ? (
                            <Route
                                key={`${route.path}Route`}
                                path={route.path}
                                element={route.element}
                            >
                                {route.subRoutes.map(
                                    ({ path: subroutePath, element: subrouteElement }) => (
                                        <Route
                                            key={`${subroutePath}Route`}
                                            path={subroutePath}
                                            element={subrouteElement}
                                        />
                                    )
                                )}
                            </Route>
                        ) : (
                            <Route
                                key={`${route.path}Route`}
                                path={route.path}
                                element={route.element}
                            />
                        )
                    )}
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export default Router
