import { useQuery } from '@tanstack/react-query'
import styled from '@emotion/styled'
import { getAllPaymentRequests } from 'paymentRequests/api'
import PageSection from 'common/components/PageSection'
import Loader from 'common/components/Loader'
import Text from 'common/components/Text'
import dayjs from 'dayjs'
import { formatCurrency } from 'common/util'
import rightIcon from 'assets/svg/right.svg'
import Table from 'common/table/Table'
import { useNavigate } from 'react-router-dom'

const RightIcon = styled.img`
    width: 16px;
    height: 16px;
`

const BoldText = styled.span`
    font-weight: 700;
`

const paymentRequestColumns = [
    {
        header: 'Vendor Name',
        id: 'vendor_name',
        accessorFn: (row) => row.vendor.organization.name ?? row.vendor.organization.legal_name,
        cell: (row) => <BoldText>{row.getValue()}</BoldText>,
    },
    {
        header: 'Invoice Date',
        id: 'invoice_date',
        accessorKey: 'invoice_date',
        cell: (row) => <span>{dayjs(row.getValue()).format('MM/DD/YYYY')}</span>,
        sortingFn: 'datetime',
    },
    {
        header: 'Due Date',
        id: 'due_date',
        accessorKey: 'payment_due_date',
        cell: (row) => <span>{dayjs(row.getValue()).format('MM/DD/YYYY')}</span>,
        sortingFn: 'datetime',
    },
    {
        header: 'Amount',
        id: 'amount',
        accessorKey: 'payment_amount',
        cell: (row) => <BoldText>{formatCurrency(row.getValue())}</BoldText>,
        sortingFn: 'basic',
    },
    {
        header: ' ',
        accessorKey: 'id',
        cell: () => <RightIcon src={rightIcon} alt="Right arrow icon" />,
    },
]

const Requests = () => {
    const navigate = useNavigate()
    const { isLoading: fetchingPaymentRequests, data: paymentRequestData } = useQuery({
        queryKey: ['fetchPaymentRequests'],
        queryFn: getAllPaymentRequests,
        staleTime: 10000,
    })
    if (fetchingPaymentRequests) return <Loader />
    return (
        <PageSection title="Pending Payment Requests" tableSection>
            <Table
                initialSort={{ id: 'due_date', desc: true }}
                data={paymentRequestData?.data ?? []}
                columns={paymentRequestColumns}
                onRowClick={(rowId) => navigate(`../new-request/${rowId}`)}
            />
            {!paymentRequestData?.data?.length && (
                <Text p="150px" centered bold>
                    You have no open payment requests.
                </Text>
            )}
        </PageSection>
    )
}

export default Requests
