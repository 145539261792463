import styled from '@emotion/styled'

const Text = styled.div`
    ${({ centered }) => centered && 'text-align: center;'}
    ${({ right }) => right && 'text-align: right;'}
    ${({ p }) => p && `padding: ${p};`}
  ${({ m }) => m && `margin: ${m};`}
  ${({ theme, variant = 'p1', bold = false, size = null }) => `
    font-size: ${size || theme.fonts[variant].fontSize};
    line-height: ${theme.fonts[variant].lineHeight};
    font-weight: ${bold ? 700 : theme.fonts[variant].fontWeight};`}
    color: ${({ secondary, color = 'inherit' }) => (secondary ? 'var(--color-dark-gray)' : color)};
    letter-spacing: ${({ spacing = 'inherit' }) => spacing};
`

export default Text
