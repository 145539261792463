export const UI_ROUTES = {
    ONBOARDING: '/onboarding',
    // TODO: Audit app for all uses of DISCOVER route
    DISCOVER: '/discover',
    GROUP_BY_ID: '/group/:groupId',
    EXPLORE: '/explore/:groupType',
    SETTINGS: '/settings',
    WALLET: '/wallet',
    WELCOME: '/welcome',
    LOGIN: '/login',
    SIGN_UP: '/sign-up',
    VERIFY_USER: '/verify-user',
    REQUEST_RESET: 'request-password-reset',
    RESET_PASSWORD: '/reset-password',
    OAUTH_CALLBACK: '/oauth/:integration',
    TRANSACTIONS: '/transactions',
    STATEMENTS: '/statements',
    INTEGRATIONS: '/integrations',
    REFERRALS: '/referrals',
    PLAID_OAUTH: '/plaid-oauth',
}

export const DISCOVER_ROUTES = {
    MY_SUMMARY: 'my-summary',
    AD_PLATFORMS: 'ad-platforms',
    MY_FINANCES: 'my-finances',
    COMMUNITY_FINANCES: 'community-finances',
}

export const WALLET_ROUTES = {
    MY_ACCOUNT: 'my-account',
    CARDS: 'cards',
    PAYMENT_ACCOUNTS: 'payment-accounts',
}

export const SETTINGS_ROUTES = {
    PROFILE: 'profile',
    TEAMS: 'teams',
    ACCOUNTS: 'accounts',
}

export const ONBOARDING_ROUTES = {
    INVITE_MEMBER_DETAILS: 'invite-member-details',
    PAYMENT_METHOD: 'payment-method',
    MEMBER_INVITES: 'invite',
    CREDIT_APPLICATION: 'credit-application',
}

export const CREDIT_APPLICATION_ROUTES = {
    QUALIFY: {
        SUBPATH: 'qualify',
        FULL_PATH: `${UI_ROUTES.ONBOARDING}/${ONBOARDING_ROUTES.CREDIT_APPLICATION}/qualify`,
    },
    ACCOUNTS: {
        SUBPATH: 'accounts',
        FULL_PATH: `${UI_ROUTES.ONBOARDING}/${ONBOARDING_ROUTES.CREDIT_APPLICATION}/accounts`,
    },
    BUSINESS_DETAILS: {
        SUBPATH: 'business-details',
        FULL_PATH: `${UI_ROUTES.ONBOARDING}/${ONBOARDING_ROUTES.CREDIT_APPLICATION}/business-details`,
    },
    ACCOUNT_VERIFICATION: {
        SUBPATH: 'account-verification',
        FULL_PATH: `${UI_ROUTES.ONBOARDING}/${ONBOARDING_ROUTES.CREDIT_APPLICATION}/account-verification`,
    },
    ACCOUNT_CONFIRMATION: {
        SUBPATH: 'account-confirmation',
        FULL_PATH: `${UI_ROUTES.ONBOARDING}/${ONBOARDING_ROUTES.CREDIT_APPLICATION}/account-confirmation`,
    },
    APPLICATION_PENDING: {
        SUBPATH: 'application-pending',
        FULL_PATH: `${UI_ROUTES.ONBOARDING}/${ONBOARDING_ROUTES.CREDIT_APPLICATION}/application-pending`,
    },
    APPLICATION_SUCCESS: {
        SUBPATH: 'application-success',
        FULL_PATH: `${UI_ROUTES.ONBOARDING}/${ONBOARDING_ROUTES.CREDIT_APPLICATION}/application-success`,
    },
}
