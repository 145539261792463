import styled from '@emotion/styled'

const Flex = styled.div`
    display: flex;
    flex-direction: ${({ column }) => (column ? 'column' : 'row')};
    justify-content: ${({ centered, centerJustify, justify = 'default' }) =>
        centered || centerJustify ? 'center' : justify};
    align-items: ${({ centered, centerAlign, align = 'default' }) =>
        centered || centerAlign ? 'center' : align};
    margin: ${({ m = 0 }) => m};
    padding: ${({ p = 0 }) => p};
    gap: ${({ gap = 0 }) => gap}px;
`

export default Flex
