import styled from '@emotion/styled'
import { Outlet, useLocation, Navigate } from 'react-router-dom'
import Flex from 'common/components/Flex'
import { useQuery } from '@tanstack/react-query'
import { fetchCurrentUser } from 'user/api'
import { fetchPlaidIntegrations } from 'plaidIntegrations/api'
import Loader from 'common/components/Loader'
import { fetchPaymentAgreements } from 'paymentAgreements/api'
import { getAllPaymentRequests } from 'paymentRequests/api'

const FixedWrapper = styled(Flex)`
    width: 100%;
    height: 100%;
`

const OnboardingOutlet = () => (
    <FixedWrapper centered>
        <Outlet />
    </FixedWrapper>
)

const Onboarding = () => {
    const { pathname } = useLocation()
    const { data: userData, isLoading: userLoading } = useQuery({
        queryKey: ['currentUser'],
        queryFn: fetchCurrentUser,
    })
    const { data: plaidIntegrationData, isLoading: integrationsLoading } = useQuery({
        queryKey: ['plaidIntegrationList'],
        queryFn: fetchPlaidIntegrations,
    })
    const { data: agreementData, isLoading: agreementsLoading } = useQuery({
        queryKey: ['fetchPaymentAgreements'],
        queryFn: fetchPaymentAgreements,
    })
    const { data: paymentRequestData, isLoading: requestsLoading } = useQuery({
        queryKey: ['fetchPaymentRequests'],
        queryFn: getAllPaymentRequests,
    })

    if (userLoading || integrationsLoading || agreementsLoading || requestsLoading) {
        return <Loader />
    }
    if (!userData?.data?.first_name) {
        return pathname.includes('buyer-details') ? (
            <OnboardingOutlet />
        ) : (
            <Navigate to="buyer-details" />
        )
    }

    if (!userData?.data?.organization.legal_name) {
        return pathname.includes('business-details') ? (
            <OnboardingOutlet />
        ) : (
            <Navigate to="business-details" />
        )
    }

    if (!plaidIntegrationData?.data?.length) {
        return pathname.includes('connect-plaid') ? (
            <OnboardingOutlet />
        ) : (
            <Navigate to="connect-plaid" />
        )
    }

    if (!agreementData?.data?.length && paymentRequestData?.data?.length) {
        return <Navigate to={`../new-request/${paymentRequestData.data[0].id}/`} />
    }

    return <Navigate to="../payments" />
}

export default Onboarding
