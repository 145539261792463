import { useParams, useNavigate } from 'react-router-dom'
import { useEffect, useMemo } from 'react'
import { isEmpty } from 'lodash'
import PaymentTermsOption from 'paymentTermsOptions/components/PaymentTermsOption'
import { fetchPaymentTermsOptions } from 'paymentTermsOptions/api'
import Loader from 'common/components/Loader'
import styled from '@emotion/styled'
import { useQuery } from '@tanstack/react-query'
import { getAllPaymentRequests } from 'paymentRequests/api'
import Logo from 'common/components/Logo'
import Tile from 'common/components/Tile'
import Flex from 'common/components/Flex'
import Text from 'common/components/Text'

const loanOptionColors = [
    'var(--color-light-pastel-blue)',
    'var(--color-light-background-blue)',
    'var(--color-light-pastel-green)',
]

const TopSection = styled(Flex)`
    margin-bottom: 20px;
`

const BottomSection = styled(Flex)`
    margin-top: 16px;
`
const PaymentOptions = () => {
    const navigate = useNavigate()
    const { paymentRequestId } = useParams()
    const { isLoading: fetchingPaymentRequests, data: paymentRequestData } = useQuery({
        queryKey: ['fetchPaymentRequests'],
        queryFn: getAllPaymentRequests,
    })
    const { isLoading: fetchingPaymentTermsOptions, data: paymentTermsOptionsData } = useQuery({
        queryKey: ['fetchPaymentTermsOptions'],
        queryFn: fetchPaymentTermsOptions,
    })

    const currPaymentRequest = useMemo(
        () => paymentRequestData?.data?.find(({ id }) => id === paymentRequestId) ?? {},
        [paymentRequestData, paymentRequestId]
    )
    useEffect(() => {
        if (isEmpty(currPaymentRequest)) {
            navigate('../../payments')
        }
    }, [currPaymentRequest, navigate])
    if (fetchingPaymentRequests || fetchingPaymentTermsOptions) return <Loader />
    return (
        <Tile
            title={
                paymentRequestId
                    ? 'Select Payment Option for New Request'
                    : 'Select a Payment Option'
            }
        >
            {paymentRequestId && (
                <TopSection>
                    <Text centered>
                        Payment term selected will be the default for all invoices. This is the
                        number of days that you have to pay after the invoice due date. Payment term
                        details will be included on each invoice.
                    </Text>
                </TopSection>
            )}
            <Flex gap="20">
                {paymentTermsOptionsData?.data?.map((paymentTermOption, index) => (
                    <PaymentTermsOption
                        key={paymentTermOption.id}
                        paymentTermOption={paymentTermOption}
                        color={loanOptionColors[index]}
                        paymentRequest={currPaymentRequest}
                    />
                ))}
            </Flex>
            <BottomSection centered gap="10" column>
                <Flex gap="5" centerAlign>
                    <Text color="#607180" variant="p2">
                        Powered by
                    </Text>
                    <Logo width="90px" />
                </Flex>
                <Text color="#607180" variant="p2">
                    *Not all businesses qualify for all payment terms. A fee may be applied for
                    longer payment term selections.
                </Text>
            </BottomSection>
        </Tile>
    )
}

export default PaymentOptions
