import styled from '@emotion/styled'
import Flex from './Flex'

export default styled(Flex)`
    ${({ noPadding = false }) => !noPadding && 'padding: 30px 38px;'}
    background: var(--color-white);
    border-radius: 12px;
    flex-direction: column;
    ${({ shadow = false }) => shadow && 'box-shadow: 0px 50px 50px -40px #00000040;'}
`
