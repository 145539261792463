import axios from 'axios'
import { GENERIC_ERROR, TOKEN_STORAGE_KEY } from 'common/constants'
import { isObject } from 'lodash'
import { errorToast } from 'common/toasts'

export const configuredApi = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT || 'http://localhost:8080',
})

const callApi = async (
    endpoint,
    method = 'get',
    config = {
        additionalHeaders: {},
        globalError: false,
        useApiError: false,
        errorMessage: null,
        errorHeadline: null,
    }
) => {
    try {
        const bearerToken = await localStorage.getItem(TOKEN_STORAGE_KEY)
        const response = await configuredApi.request({
            url: endpoint,
            headers: {
                ...config.additionalHeaders,
                Authorization: `Bearer ${bearerToken}`,
            },
            method,
            ...config,
        })
        return Promise.resolve(response)
    } catch (error) {
        const errorDetailFromApi = error?.response?.data?.detail
        if (config.globalError) {
            const message =
                config.errorMessage || (config.useApiError ? errorDetailFromApi : GENERIC_ERROR)
            errorToast(message, config.errorHeadline)
        }
        const errorDetail = isObject(errorDetailFromApi)
            ? JSON.stringify(errorDetailFromApi)
            : errorDetailFromApi
        return Promise.reject(new Error(errorDetail || GENERIC_ERROR))
    }
}

export default callApi
