import styled from '@emotion/styled'
import { useMutation } from '@tanstack/react-query'
import { errorToast } from 'common/toasts'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { inviteBuyer } from 'organizations/api'
import userIcon from 'assets/svg/user.svg'
import Flex from 'common/components/Flex'
import PageSection from 'common/components/PageSection'
import TextInput from 'common/components/TextInput'
import Button from 'common/components/Button'
import TextArea from 'common/components/TextArea'
import DateInput from 'common/components/DateInput'
import CurrencyInput from 'common/components/CurrencyInput'
import PaymentDueDateFields from 'paymentRequests/components/PaymentDueDateFields'
import queryClient from 'api/query'
import dayjs from 'dayjs'

const CreateBuyerForm = styled.form`
    display: grid;
    grid-template-rows: 1fr 1fr auto auto;
    grid-template-columns: 1fr 1fr;
    gap: 10px 20px;
`

const TextAreaWrapper = styled.div`
    grid-column: 1 / span 2;
`

const CreateBuyer = () => {
    const navigate = useNavigate()
    const { isLoading, mutate } = useMutation({
        mutationFn: inviteBuyer,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [`listBuyers`] })
            navigate('../')
        },
        onError: (error) => errorToast(error?.detail),
    })
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm({ reValidateMode: 'onSubmit' })
    const currentInvoiceDate = watch('invoice_date')
    const onSubmit = useCallback((data) => mutate(data), [mutate])
    return (
        <Flex column gap="30" p="20px 0">
            <PageSection icon={userIcon} title="Add a customer">
                <CreateBuyerForm onSubmit={handleSubmit(onSubmit)}>
                    <TextInput
                        label="Customer Name"
                        inputRef={() => register('buyer_name', { required: true })}
                        placeholder="i.e. Matt's Masks"
                        errors={errors}
                        showLabel
                    />
                    <TextInput
                        label="Customer Email"
                        inputRef={() => register('buyer_email', { required: true })}
                        placeholder="i.e. matt@mattsmasks.co"
                        errors={errors}
                        showLabel
                    />
                    <CurrencyInput
                        label="Payment Amount (optional)"
                        inputRef={() =>
                            register('payment_amount', {
                                validate: (value) =>
                                    value === '' || value >= 20 || 'Amount must be more than $20.',
                            })
                        }
                        placeholder="0.00"
                        watch={watch}
                        errors={errors}
                    />
                    <DateInput
                        label="Invoice Date (optional)"
                        inputRef={() =>
                            register('invoice_date', {
                                validate: (value) =>
                                    value === '' ||
                                    dayjs().isSameOrBefore(value) ||
                                    'Invoice date must not be in the past.',
                            })
                        }
                        watch={watch}
                        errors={errors}
                    />
                    <PaymentDueDateFields view="buyer" invoiceDate={currentInvoiceDate} />
                    <TextAreaWrapper>
                        <TextArea
                            label="Memo (optional)"
                            inputRef={() => register('memo')}
                            errors={errors}
                            placeholder="i.e. For service X and Y"
                        />
                    </TextAreaWrapper>
                    <Flex gap="20" centerAlign>
                        <Button size="small" type="submit" loading={isLoading}>
                            Request
                        </Button>
                        <Button variant="tertiary" onClick={() => navigate('../')}>
                            Cancel
                        </Button>
                    </Flex>
                </CreateBuyerForm>
            </PageSection>
        </Flex>
    )
}

export default CreateBuyer
