import styled from '@emotion/styled'
import { useQuery } from '@tanstack/react-query'
import Loader from 'common/components/Loader'
import PageSection from 'common/components/PageSection'
import Table from 'common/table/Table'
import { formatCurrency } from 'common/util'
import dayjs from 'dayjs'
import { fetchPaymentAgreements } from 'paymentAgreements/api'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import rightIcon from 'assets/svg/right.svg'
import StatusCell from 'common/table/StatusCell'
import { paymentAgreementStatusMap } from 'common/statusMaps'
import PaymentDetailsModal from 'paymentAgreements/components/PaymentDetailsModal'
import { fetchCurrentUser } from 'user/api'

const RightIcon = styled.img`
    width: 16px;
    height: 16px;
`

const BoldText = styled.span`
    font-weight: 700;
`

const paymentColumns = [
    {
        id: 'vendor_name',
        header: 'Vendor',
        accessorKey: 'vendor_name',
        cell: (row) => <BoldText>{row.getValue()}</BoldText>,
    },
    {
        header: 'Invoice Date',
        id: 'invoice_date',
        accessorFn: (row) => row.details.request_invoice_date,
        cell: (row) => <span>{dayjs(row.getValue()).format('MM/DD/YYYY')}</span>,
    },
    {
        id: 'terms',
        header: 'Terms',
        accessorFn: (row) => row.details.number_of_days,
        cell: (row) => <span>{row.getValue()} days</span>,
        sortingFn: 'basic',
    },
    {
        header: 'Due Date',
        id: 'due_date',
        accessorFn: (row) => row.details.payment_due_date,
        cell: (row) => <span>{dayjs(row.getValue()).format('MM/DD/YYYY')}</span>,
        sortingFn: 'datetime',
    },
    {
        header: 'Payment Status',
        id: 'payment_status',
        accessorFn: (row) => row.attempt_status || 1,
        sortDescFirst: true,
        cell: (row) => (
            <StatusCell
                value={paymentAgreementStatusMap[row.getValue()].text}
                statusColor={paymentAgreementStatusMap[row.getValue()].color}
                tableCell
            />
        ),
        sortingFn: 'basic',
    },
    {
        header: 'Amount',
        id: 'amount',
        accessorKey: 'market_buyer_final_amount',
        cell: (value) => <BoldText>{formatCurrency(value.getValue())}</BoldText>,
    },
    {
        header: ' ',
        accessorKey: 'id',
        cell: () => <RightIcon src={rightIcon} alt="Right arrow icon" />,
    },
]

const Payments = () => {
    const { paymentId } = useParams()
    const navigate = useNavigate()
    const { isFetching, data } = useQuery({
        queryKey: ['fetchPaymentAgreements'],
        queryFn: fetchPaymentAgreements,
        staleTime: 10000,
    })
    const { isFetching: userFetching } = useQuery({
        queryKey: ['fetchCurrentUser'],
        queryFn: fetchCurrentUser,
    })
    useEffect(() => {
        if (!isFetching && !data?.data?.length) {
            navigate(`../onboarding`, { replace: true })
        }
    }, [navigate, isFetching, data])
    if (isFetching || userFetching) return <Loader />
    return (
        <>
            <PageSection title="Current Payments" tableSection>
                <Table
                    initialSort={{ id: 'due_date', desc: true }}
                    data={data?.data}
                    columns={paymentColumns}
                    onRowClick={(rowId) => navigate(rowId)}
                />
            </PageSection>
            <PaymentDetailsModal
                isOpen={!!paymentId}
                onRequestClose={() => navigate('../payments')}
            />
        </>
    )
}

export default Payments
