import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import analytics from 'analytics'
import { WALLET_ROUTES } from 'routes'

const usePageInit = () => {
    const { pathname } = useLocation()
    useEffect(() => {
        const trackPageView = () => {
            analytics.track('trustUI:page_viewed', { url: pathname })
        }
        trackPageView()
        if (pathname !== WALLET_ROUTES.MY_ACCOUNT) {
            window.scrollTo(0, 0)
        }
    }, [pathname])
}

export default usePageInit
