import { values } from 'lodash'
import { navIconData } from 'assets/svg/icons/nav'
import referralsIcon from '../assets/emojis/referrals-emoji.png'
import { CARD_STATUS, ROLE_TYPE } from './enums'

// eslint-disable-next-line import/prefer-default-export
export const TOKEN_STORAGE_KEY = 'MarketBearerToken'

export const POST_PAGE_SIZE = 9

export const TRANSACTION_PAGE_SIZE = 25
export const STATEMENTS_PAGE_SIZE = 10

export const INTEGRATION_TYPE = {
    PLAID: 1,
    QUICKBOOKS: 2,
    RUTTER: 3,
    IMPROVADO: 4,
    CODAT: 8,
}

export const NAV_ITEMS = {
    DISCOVER: {
        PATHS: ['/discover'],
        TITLE: 'Insights',
        ICON: navIconData.discover,
        LINK: '/discover',
        DISPLAY: true,
        ROLES_ALLOWED: [ROLE_TYPE.ACCOUNTING, ROLE_TYPE.ADMIN, ROLE_TYPE.ANALYST],
        SUB_NAV_COLOR: 'var(--color-pastel-blue)',
        SUB_ITEMS: [
            {
                PATH: '/my-summary',
                TITLE: 'My Summary',
            },
            {
                PATH: '/my-finances',
                TITLE: 'My Finances',
            },
            {
                PATH: '/ad-platforms',
                TITLE: 'Ad Platforms',
            },
        ],
    },
    EXPLORE: {
        PATHS: ['/explore', '/group'],
        TITLE: 'Explore',
        ICON: navIconData.explore,
        DISPLAY: false,
        LINK: '/explore/all',
        ROLES_ALLOWED: [...values(ROLE_TYPE)],
    },
    WALLET: {
        PATHS: ['/wallet'],
        TITLE: 'Wallet',
        ICON: navIconData.wallet,
        LINK: '/wallet',
        DISPLAY: true,
        ROLES_ALLOWED: [ROLE_TYPE.ACCOUNTING, ROLE_TYPE.ADMIN, ROLE_TYPE.CARD_HOLDER],
        SUB_NAV_COLOR: 'var(--color-light-green)',
        SUB_ITEMS: [
            {
                PATH: '/my-account',
                TITLE: 'My Account',
            },
            {
                PATH: '/cards',
                TITLE: 'Cards',
            },
            {
                PATH: '/payment-accounts',
                TITLE: 'Payment Accounts',
            },
        ],
    },
    PAYMENT_METHODS: {
        PATHS: ['/wallet/payment-accounts'],
        DISPLAY: false,
        LINK: '/wallet/payment-accounts',
        ROLES_ALLOWED: [ROLE_TYPE.ADMIN, ROLE_TYPE.ACCOUNTING],
    },
    INTEGRATIONS: {
        PATHS: ['/integrations'],
        TITLE: 'Integrations',
        ICON: navIconData.integrations,
        LINK: '/integrations',
        DISPLAY: true,
        ROLES_ALLOWED: [ROLE_TYPE.ADMIN, ROLE_TYPE.ACCOUNTING],
    },
    REFERRALS: {
        PATHS: ['/referrals'],
        TITLE: 'Referrals',
        ICON: referralsIcon,
        LINK: '/referrals',
        DISPLAY: false,
        ROLES_ALLOWED: [ROLE_TYPE.ADMIN],
    },
    SETTINGS: {
        PATHS: ['/settings'],
        TITLE: 'Settings',
        LINK: '/settings',
        DISPLAY: false,
        ROLES_ALLOWED: [ROLE_TYPE.ADMIN],
    },
    PLAID_OAUTH: {
        PATHS: ['/plaid-oauth'],
        TITLE: '',
        LINK: '/plaid-oauth',
        DISPLAY: false,
        ROLES_ALLOWED: [ROLE_TYPE.ADMIN],
    },
}

export const LOADING_STATES = {
    IDLE: 'idle',
    CREATE: 'create',
    UPDATE: 'update',
    DELETE: 'delete',
    FETCH_ONE: 'fetchOne',
    FETCH_ALL: 'fetchAll',
    FETCH_MORE: 'fetchMore',
    FAILED: 'failed',
}

export const USER_TYPE = {
    ADMIN: 1,
    REGULAR: 0,
}

export const BUSINESS_COLORS = {
    snapchat: '#EAEF00',
    snap: '#EAEF00',
    twitter: '#1DA0F1',
    tiktok: '#7538F7',
    facebook: '#385DF7',
    linkedin: '#2CB473',
    pinterest: '#F33738',
    amazon: '#feaa40',
    'google ads': '#4baf4f',
}

export const GENERIC_ERROR =
    'Something went wrong. Please try again and if the error persists, reach out to support@getmarket.com.'

export const PW_STRENGTH_ERROR =
    'Password must be 10 characters or longer, include at least one uppercase letter, and include at least one number.'

export const PLATFORMS = [
    {
        displayName: 'Facebook',
        keys: ['facebook', 'Facebook'],
    },
    {
        displayName: 'Instagram',
        keys: ['instagram', 'Instagram'],
    },
    {
        displayName: 'Pinterest',
        keys: ['pinterest', 'Pinterest'],
    },
    {
        displayName: 'Snap',
        keys: ['snap', 'Snap', 'snapchat', 'Snapchat'],
    },
    {
        displayName: 'Twitter',
        keys: ['twitter', 'Twitter'],
    },
    {
        displayName: 'Google',
        keys: ['google', 'Google', 'google ads', 'Google Ads'],
    },
    {
        displayName: 'Amazon',
        keys: ['amazon', 'Amazon'],
    },
    {
        displayName: 'TikTok',
        keys: ['tiktok', 'Tiktok', 'TikTok'],
    },
]

export const ROLE_DISPLAY_MAP = {
    ADMIN: 'Admin',
    CARD_HOLDER: 'Cardholder',
    ANALYST: 'Analyst',
    ACCOUNTING: 'Accounting',
}

export const emailTakenMessage = 'A user with this email already exists in the system.'

export const MY_FINANCE_EMPTY_STATES = ['empty2by2', 'empty1by2', 'empty1by1', 'empty1by1_2']

export const CARD_STATUS_MAP = {
    lock: CARD_STATUS.INACTIVE,
    unlock: CARD_STATUS.ACTIVE,
    terminate: CARD_STATUS.TERMINATED,
}
