import { useMemo } from 'react'
import { capitalize, isEmpty } from 'lodash'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import Flex from './Flex'

const StyledInput = styled.input`
    all: unset;
    box-sizing: border-box;
    padding: ${({ hasIcon }) => (hasIcon ? '9px 8px' : '12px 10px')};
    border-radius: 6px;
    ${({ hasIcon }) => hasIcon && 'padding-left: 24px'};
    transition: border-color 0.2s ease, background-color 0.2s ease, color 0.2s ease;
    width: 100%;
    text-align: left;
    background-color: var(--color-snow-white);
    color: var(--color-black);
    border: 1px solid transparent;
    ${({ isSuccess }) =>
        isSuccess &&
        `border-color: var(--color-green);
        background-color: var(--color-light-green);
        color: var(--color-green);
        padding-right: 20px;
        `}
    :hover {
        background-color: var(--color-snow-white);
        border-color: var(--color-medium-light-gray);
    }
    :focus {
        border-color: var(--color-blue);
        background-color: var(--color-snow-white);
    }
    ${({ hasError }) =>
        hasError &&
        `border-color: var(--color-red);
        background-color: var(--color-light-red);
        color: var(--color-red);
        :hover {
            background-color: var(--color-light-red);
            border-color: var(--color-red);
        }
        :focus {
            border-color: var(--color-red);
            background-color: var(--color-light-red);
        }
    `}
    :disabled {
        border-color: transparent;
        color: var(--color-dark-gray);
        background-color: var(--color-snow-white);
    }
    ::placeholder {
        color: var(--color-medium-light-gray);
        font-size: 16px;
    }
`

const ColorHolder = styled(Flex)`
    width: 100%;
    ${({ errorPotential, hasError }) => errorPotential && !hasError && 'padding-bottom: 17px'};
`

const InputWrapper = styled.div`
    position: relative;
    height: 45px;
`

const ErrorMessage = styled.div`
    color: var(--color-red);
    font-size: 10px;
    font-style: italic;
`

const Label = styled.label`
    font-size: 14px;
    font-weight: 200;
    color: var(--color-dark-gray);
`

const TextInput = ({
    inputRef,
    name,
    label,
    className,
    errors,
    errorMessage,
    isSuccess,
    showLabel = false,
    ...rest
}) => {
    const inputName = useMemo(() => name ?? inputRef()?.name, [name, inputRef])
    const hasError = errors && !isEmpty(errors[inputName])
    const eMessage =
        errorMessage ||
        (hasError && errors[inputName].message) ||
        `${capitalize(label ?? inputName)} is a required field.`
    const inputProps = {
        type: 'text',
        name: inputName,
        hasError,
        isSuccess,
    }
    return (
        <ColorHolder
            errorPotential={errors}
            hasError={hasError}
            className={className}
            gap="5"
            column
        >
            {label && showLabel && <Label htmlFor={inputName}>{label}</Label>}
            <InputWrapper>
                {inputRef ? (
                    <StyledInput {...inputProps} {...inputRef()} {...rest} />
                ) : (
                    <StyledInput {...inputProps} {...rest} />
                )}
            </InputWrapper>
            {hasError && <ErrorMessage>{eMessage}</ErrorMessage>}
        </ColorHolder>
    )
}

TextInput.propTypes = {
    inputRef: PropTypes.func,
    className: PropTypes.string,
    errors: PropTypes.object,
    name: PropTypes.string,
    variant: PropTypes.string,
    icon: PropTypes.string,
    errorMessage: PropTypes.string,
    errorStyles: PropTypes.object,
    label: PropTypes.string,
    showLabel: PropTypes.bool,
    isSuccess: PropTypes.bool,
}

export default TextInput
