import { useMemo } from 'react'
import styled from '@emotion/styled'
import { useQuery } from '@tanstack/react-query'
import { useLocation } from 'react-router-dom'
import useAvailableRoutes from 'common/hooks/useAvailableRoutes'
import { fetchCurrentUser } from 'user/api'
import Text from './Text'
import Flex from './Flex'

const HeaderWrap = styled(Flex)`
    margin: 20px 0;
    padding: 0px 4px;
    align-items: center;
    justify-content: space-between;
`

const UserInfo = styled(Text)`
    margin-left: 34px;
    padding-top: 4px;
`

const Header = () => {
    const { pathname } = useLocation()
    const routes = useAvailableRoutes()
    const { data } = useQuery({
        queryKey: ['currentUser'],
        queryFn: fetchCurrentUser,
        enabled: false,
        useErrorBoundary: false,
    })
    const title = useMemo(() => {
        const currNavItem = routes.find((item) => pathname.includes(item.path))
        return <Text variant="h1">{currNavItem?.label}</Text>
    }, [pathname, routes])
    return (
        <HeaderWrap>
            {title}
            <UserInfo variant="h2">
                {data?.data?.first_name} {data?.data?.last_name}
            </UserInfo>
        </HeaderWrap>
    )
}

export default Header
