import discoverIcon from './discover.svg'
import walletIcon from './wallet.svg'
import integrationsIcon from './integrations.svg'
import exploreIcon from './explore.svg'

// eslint-disable-next-line import/prefer-default-export
export const navIconData = {
    discover: discoverIcon,
    wallet: walletIcon,
    integrations: integrationsIcon,
    explore: exploreIcon,
}
