import Logo from 'common/components/Logo'
import Loader from 'common/components/Loader'
import Text from 'common/components/Text'
import styled from '@emotion/styled'
import Flex from 'common/components/Flex'
import Card from 'common/components/Card'

const MaxFlex = styled(Flex)`
    height: 100%;
    width: 100%;
`

const FixedSizeCard = styled(Card)`
    height: 550px;
    width: 550px;
`

const ProcessingScreen = () => (
    <MaxFlex centered>
        <FixedSizeCard gap="20" shadow centered>
            <Logo />
            <Loader />
            <Text variant="p1" bold>
                Processing...
            </Text>
        </FixedSizeCard>
    </MaxFlex>
)

export default ProcessingScreen
