import PropTypes from 'prop-types'
import Flex from 'common/components/Flex'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import OnboardingCard from 'common/components/OnboardingCard'
import Logo from 'common/components/Logo'
import Text from 'common/components/Text'

const CardWrap = styled(OnboardingCard)`
    max-width: ${({ maxWidth }) => maxWidth};
    min-width: 480px;
    padding: 46px 92px 68px 92px;
`

const SlideFadeUpAndOut = styled(motion.div)`
    display: flex;
    justify-content: center;
    align-items: center;
`

const SizedLogo = styled(Logo)`
    ${({ $centerLogo }) => $centerLogo && 'margin: 0 auto;'}
    margin-bottom: 22px;
    width: 141px;
    height: 41px;
`

const Title = styled.div`
    font-size: 24px;
    letter-spacing: 1px;
    font-weight: 600;
    margin-bottom: 12px;
`

const SubHead = styled(Text)`
    font-size: 13px;
    color: var(--color-medium-dark-gray);
    margin-bottom: 20px;
`

const SinglePageCard = ({
    children,
    logo,
    centerLogo = false,
    title,
    subHead,
    maxWidth,
    cardLogo = false,
}) => (
    <SlideFadeUpAndOut
        initial={{ opacity: 0, y: 30 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ ease: 'easeInOut', duration: 0.4 }}
    >
        <CardWrap maxWidth={maxWidth}>
            {logo && (
                <Flex centered={!!centerLogo}>
                    <SizedLogo cardLogo={cardLogo} />
                </Flex>
            )}
            {title && (
                <Flex centered={!!centerLogo}>
                    <Title>{title}</Title>
                </Flex>
            )}
            {subHead && (
                <Flex centered={!!centerLogo}>
                    <SubHead>{subHead}</SubHead>
                </Flex>
            )}
            {children}
        </CardWrap>
    </SlideFadeUpAndOut>
)

SinglePageCard.propTypes = {
    children: PropTypes.node,
    logo: PropTypes.bool,
    centerLogo: PropTypes.bool,
    title: PropTypes.string,
    subHead: PropTypes.string,
    cardLogo: PropTypes.bool,
    maxWidth: PropTypes.string,
}

export default SinglePageCard
