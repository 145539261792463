import { useCallback } from 'react'
import { errorToast } from 'common/toasts'
import { usePlaidLink } from 'react-plaid-link'
import { useMutation, useQuery } from '@tanstack/react-query'
import PlaidIntegration from 'plaidIntegrations/components/PlaidIntegration'
import { PLAID_CONTEXT, PLAID_LINK_TOKEN, SETTINGS } from 'common/localStorage'
import Flex from 'common/components/Flex'
import PageSection from 'common/components/PageSection'
import Button from 'common/components/Button'
import {
    createPlaidIntegration,
    fetchLinkToken,
    fetchPlaidIntegrations,
} from 'plaidIntegrations/api'
import { fetchCurrentUser } from 'user/api'
import Loader from 'common/components/Loader'
import queryClient from 'api/query'

const Settings = () => {
    const { data: linkTokenData } = useQuery({
        queryKey: ['plaidLinkToken'],
        queryFn: fetchLinkToken,
    })
    const { data: userData } = useQuery({
        queryKey: ['currentUser'],
        queryFn: fetchCurrentUser,
    })
    const { data: plaidIntegrationData, isLoading: fetching } = useQuery({
        queryKey: ['plaidIntegrationList'],
        queryFn: fetchPlaidIntegrations,
    })
    const { mutate, isLoading: creating } = useMutation({
        mutationKey: ['createPlaidIntegration'],
        mutationFn: createPlaidIntegration,
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ['plaidIntegrationList'] }),
        onError: () => {
            errorToast()
        },
    })
    const onSuccess = useCallback(
        async (publicToken, meta) => {
            if (meta.account.subtype !== 'checking' && meta.account.subtype !== 'savings') {
                errorToast(
                    'Please select a checking or savings account for your initial account. You may add other accounts later in the Settings view.'
                )
                return
            }
            mutate({
                public_token: publicToken,
                meta,
                organization_id: userData?.data?.organization?.id,
            })
        },
        [mutate, userData]
    )
    const handlePrimarySelect = useCallback(() => null, [])
    const { open, ready } = usePlaidLink({ token: linkTokenData?.data?.link_token, onSuccess })
    const setStorageItems = useCallback(() => {
        localStorage.setItem(PLAID_LINK_TOKEN, linkTokenData?.data?.link_token)
        localStorage.setItem(PLAID_CONTEXT, SETTINGS)
    }, [linkTokenData])
    if (fetching || creating) return <Loader />
    return (
        <PageSection title="Select primary bank account">
            <Flex gap="10" m="0 0 10px" column>
                {plaidIntegrationData?.data?.map((pi) => (
                    <PlaidIntegration
                        key={pi.id}
                        plaidIntegration={pi}
                        handlePrimarySelect={handlePrimarySelect}
                    />
                ))}
            </Flex>
            <Button
                onClick={() => {
                    setStorageItems()
                    open()
                }}
                loading={!ready}
                size="full"
            >
                Add Bank Account
            </Button>
        </PageSection>
    )
}

export default Settings
