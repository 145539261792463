/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback, useState } from 'react'
import { TailSpin } from 'react-loader-spinner'
import styled from '@emotion/styled'
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'
import PropTypes from 'prop-types'
import { pick, values, isEmpty } from 'lodash'
import TextInput from 'common/components/TextInput'
import Flex from 'common/components/Flex'

const countryMap = {
    'United States': 'USA',
    'Puerto Rico': 'PRI',
}

const addressParts = {
    street_number: 'street_number',
    route: 'street_address',
    locality: 'city_town',
    administrative_area_level_1: 'district',
    country: 'country',
    postal_code: 'postal_area',
}

const Wrapper = styled.div`
    position: relative;
    ${({ fullWidth }) => fullWidth && 'width: 100%;'}
`

const DropdownWrap = styled.div`
    position: absolute;
    min-height: 100px;
    top: 78%;
    border-radius: 6px;
    border: 1px solid var(--color-black);
    max-height: 200px;
    overflow-y: auto;
    background: var(--color-background);
    color: var(--color-black);
    ::-webkit-scrollbar {
        width: 0px;
        background: transparent;
    }
    z-index: 2;
    width: 100%;
`

const ErrorText = styled.div`
    padding-top: 16px;
    margin-top: 16px;
`

const Suggestion = styled.div`
    cursor: pointer;
    padding: 8px 6px;
    :hover {
        background-color: var(--color-white);
    }
`

const AddressInput = ({
    register,
    errors,
    setValue,
    triggerValidation,
    prepop,
    label,
    name,
    variant,
    fullWidth,
    ...rest
}) => {
    const [input, setInput] = useState('')
    const [loading, setLoading] = useState(false)
    const [googleError, setGoogleError] = useState(null)
    const [showDropdown, setShowDropdown] = useState(false)
    const autoCompleteError = pick(errors, values(addressParts))
    const error = isEmpty(autoCompleteError)
        ? null
        : { type: 'validation', message: 'Please enter a valid address' }

    const fillInAddress = async (address) => {
        setLoading(true)
        const selectedAddress = {}
        try {
            const response = await geocodeByAddress(address)
            response[0].address_components.forEach((component) => {
                const componentType = component.types[0]
                if (componentType === 'administrative_area_level_1') {
                    selectedAddress[componentType] = component.short_name
                } else if (componentType === 'country') {
                    selectedAddress[componentType] = countryMap[component.long_name]
                } else {
                    selectedAddress[componentType] = component.long_name
                }
                if (component.types.includes('sublocality')) {
                    selectedAddress.sublocality = component.long_name
                }
            })
            setInput(response[0].formatted_address)
            Object.keys(addressParts).forEach((part) => {
                let value = selectedAddress[part] || null
                if (value === null && part === 'locality') {
                    if (selectedAddress?.sublocality === 'Queens') {
                        value = selectedAddress?.neighborhood
                    } else {
                        value = selectedAddress?.sublocality
                    }
                }
                setValue(addressParts[part], value)
                triggerValidation(addressParts[part])
            })
            setShowDropdown(false)
        } catch (e) {
            if (e === 'ZERO_RESULTS') {
                setGoogleError('No results found.')
            } else {
                setGoogleError(
                    'We had an error reaching Google, please refresh the page and try again.'
                )
            }
        } finally {
            setLoading(false)
        }
    }

    const invalidateInputs = useCallback(() => {
        Object.keys(addressParts).forEach((part) => {
            document.getElementById(part).value = null
            triggerValidation(addressParts[part])
        })
    }, [addressParts])

    useEffect(() => {
        if (prepop) {
            setInput(prepop.addressString)
            Object.keys(addressParts).forEach((part) => {
                const value = prepop.addressComponents[part] || null
                document.getElementById(part).value = value
                triggerValidation(addressParts[part])
            })
        }
        setShowDropdown(false)
    }, [])
    return (
        <>
            <PlacesAutocomplete
                value={input}
                onChange={(e) => {
                    setShowDropdown(true)
                    setInput(e)
                    setGoogleError(null)
                    invalidateInputs()
                }}
                onSelect={fillInAddress}
                searchOptions={{
                    types: ['address'],
                    componentRestrictions: { country: ['US', 'pr'] },
                }}
            >
                {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                    <Wrapper fullWidth={fullWidth}>
                        <TextInput
                            name={name}
                            label={label}
                            {...rest}
                            {...getInputProps()}
                            autoComplete="chrome-off"
                            variant={variant}
                            errors={error ? { [name]: error } : {}}
                            onFocus={() => setShowDropdown(true)}
                            onBlur={() => setShowDropdown(false)}
                        />
                        {showDropdown && (
                            <DropdownWrap>
                                {loading && (
                                    <Flex centered>
                                        <TailSpin height={18} type="TailSpin" />
                                    </Flex>
                                )}
                                {googleError && !loading && <ErrorText>{googleError}</ErrorText>}
                                {isEmpty(suggestions) && !loading && !googleError && (
                                    <Flex centered style={{ padding: '20px' }}>
                                        <span>Start typing and suggestions will appear</span>
                                    </Flex>
                                )}
                                {suggestions.map((suggestion) => (
                                    <Suggestion
                                        {...getSuggestionItemProps(suggestion)}
                                        key={suggestion.placeId}
                                    >
                                        {suggestion.description}
                                    </Suggestion>
                                ))}
                            </DropdownWrap>
                        )}
                    </Wrapper>
                )}
            </PlacesAutocomplete>
            {Object.keys(addressParts).map((component) => (
                <input
                    tabIndex="-1"
                    key={component}
                    id={component}
                    type="hidden"
                    {...register(addressParts[component], { required: true })}
                />
            ))}
        </>
    )
}

AddressInput.propTypes = {
    triggerValidation: PropTypes.func,
    register: PropTypes.func,
    setValue: PropTypes.func,
    errors: PropTypes.object,
    prepop: PropTypes.object,
    label: PropTypes.string,
    name: PropTypes.string,
    variant: PropTypes.string,
    fullWidth: PropTypes.bool,
}

export default AddressInput
