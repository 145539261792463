import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Flex from './Flex'

const LoaderHolder = styled(Flex)`
    padding: 10px;
`

const AnimatedLoader = styled.div`
    width: ${({ width }) => `${width}px`};
    height: ${({ height }) => `${height}px`};
    --c: linear-gradient(${({ color }) => color} 0 0);
    --r1: radial-gradient(farthest-side at bottom, ${({ color }) => color} 93%, #0000);
    --r2: radial-gradient(farthest-side at top, ${({ color }) => color} 93%, #0000);
    background: var(--c), var(--r1), var(--r2), var(--c), var(--r1), var(--r2), var(--c), var(--r1),
        var(--r2);
    background-repeat: no-repeat;
    animation: db2 0.8s infinite alternate;

    @keyframes db2 {
        0%,
        25% {
            background-size: 8px 0, 8px 4px, 8px 4px, 8px 0, 8px 4px, 8px 4px, 8px 0, 8px 4px,
                8px 4px;
            background-position: 0 50%, 0 calc(50% - 2px), 0 calc(50% + 2px), 50% 50%,
                50% calc(50% - 2px), 50% calc(50% + 2px), 100% 50%, 100% calc(50% - 2px),
                100% calc(50% + 2px);
            animation-timing-function: ease-in;
        }
        50% {
            background-size: 8px 100%, 8px 4px, 8px 4px, 8px 0, 8px 4px, 8px 4px, 8px 0, 8px 4px,
                8px 4px;
            background-position: 0 50%, 0 calc(0% - 2px), 0 calc(100% + 2px), 50% 50%,
                50% calc(50% - 2px), 50% calc(50% + 2px), 100% 50%, 100% calc(50% - 2px),
                100% calc(50% + 2px);
            animation-timing-function: linear;
        }
        75% {
            background-size: 8px 100%, 8px 4px, 8px 4px, 8px 100%, 8px 4px, 8px 4px, 8px 0, 8px 4px,
                8px 4px;
            background-position: 0 50%, 0 calc(0% - 2px), 0 calc(100% + 2px), 50% 50%,
                50% calc(0% - 2px), 50% calc(100% + 2px), 100% 50%, 100% calc(50% - 2px),
                100% calc(50% + 2px);
            animation-timing-function: ease-out;
        }
        95%,
        100% {
            background-size: 8px 100%, 8px 4px, 8px 4px, 8px 100%, 8px 4px, 8px 4px, 8px 100%,
                8px 4px, 8px 4px;
            background-position: 0 50%, 0 calc(0% - 2px), 0 calc(100% + 2px), 50% 50%,
                50% calc(0% - 2px), 50% calc(100% + 2px), 100% 50%, 100% calc(0% - 2px),
                100% calc(100% + 2px);
        }
    }
`

const Loader = ({ color = 'var(--color-black)', height = 40, width = 40, ...rest }) => {
    return (
        <LoaderHolder centered>
            <AnimatedLoader color={color} height={height} width={width} {...rest} />
        </LoaderHolder>
    )
}

Loader.propTypes = {
    color: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
}

export default Loader
