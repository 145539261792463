import colors from './colors'

// const dark = {
//     fontSizes: {
//         h1: '32px',
//         h2: '20px',
//         body: '16px',
//     },
//     colors: {
//         ...colors.dark,
//         primary: colors.dark.brightBlue,
//         primaryDark: colors.dark.darkBlue,
//         border: colors.dark.lightGray,
//         background: '#1C1927',
//         backgroundLight: '#211D2F',
//         surfaceOne: colors.dark.grayBlue,
//         surfaceTwo: colors.dark.lightGrayBlue,
//         onBackground: colors.dark.white,
//         onSurfaceOne: colors.dark.white,
//         onSurfaceTwo: colors.dark.purplishGray,
//         secondaryText: colors.dark.mediumGray,
//     },
// }

const light = {
    fonts: {
        h1: {
            fontSize: '32px',
            lineHeight: '36px',
            fontWeight: 700,
        },
        h2: {
            fontSize: '24px',
            lineHeight: '26px',
            fontWeight: 600,
        },
        h3: {
            fontSize: '18px',
            lineHeight: '20px',
            fontWeight: 600,
        },
        p1: {
            fontSize: '16px',
            lineHeight: '18px',
            fontWeight: 300,
        },
        p2: {
            fontSize: '14px',
            lineHeight: '16px',
            fontWeight: 300,
        },
        d: {
            fontSize: '12px',
            lineHeight: '14px',
            fontWeight: 500,
        },
    },
    colors,
}

const themes = {
    light,
}

export default themes
