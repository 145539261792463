import { toast } from 'react-hot-toast'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import Button from 'common/components/Button'
import Flex from 'common/components/Flex'
import Text from 'common/components/Text'
import { GENERIC_ERROR } from './constants'

const MaxWidthFlex = styled(Flex)`
    width: 100%;
    padding: 0px 20px;
`

const PositionedButton = styled(Button)`
    transform: scale(0.7);
    color: var(--color-medium-dark-gray);
    :hover {
        color: var(--color-medium-light-gray);
    }
    position: absolute;
    top: 8px;
    right: 8px;
`

const Toast = ({ headline, message, dismiss }) => (
    <>
        <MaxWidthFlex centerAlign>
            <Flex column>
                <Text variant="h3">{headline}</Text>
                <Text variant="p2">{message}</Text>
            </Flex>
        </MaxWidthFlex>
        <PositionedButton variant="iconOnly" icon="circleX" onClick={dismiss} />
    </>
)

Toast.propTypes = {
    headline: PropTypes.string,
    message: PropTypes.string,
    dismiss: PropTypes.func,
}

export const successToast = (message, headline = 'Success') =>
    toast.success((t) => (
        <Toast headline={headline} message={message} dismiss={() => toast.dismiss(t.id)} />
    ))

export const errorToast = (message = GENERIC_ERROR, headline = 'Error') =>
    toast.error(
        (t) => <Toast headline={headline} message={message} dismiss={() => toast.dismiss(t.id)} />,
        { id: `${message}Error` }
    )

export const copyToast = (message, headline = 'Copied to Clipboard') =>
    toast(
        (t) => <Toast headline={headline} message={message} dismiss={() => toast.dismiss(t.id)} />,
        {
            id: 'copy',
            icon: (
                <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle cx="15" cy="15" r="15" fill="var(--color-blue)" />
                    <path
                        d="M17 10.25V9C17 8.44772 16.5523 8 16 8H10C9.44772 8 9 8.44772 9 9V16C9 16.5523 9.44772 17 10 17H11.2857"
                        stroke="var(--color-black)"
                        strokeWidth="2"
                        strokeLinecap="round"
                    />
                    <rect
                        x="14"
                        y="13"
                        width="8"
                        height="9"
                        rx="1"
                        stroke="var(--color-black)"
                        strokeWidth="2"
                    />
                </svg>
            ),
        }
    )
