import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { useNavigate, useParams } from 'react-router-dom'
import Flex from 'common/components/Flex'
import Button from 'common/components/Button'
import Text from 'common/components/Text'
import { formatCurrency } from 'common/util'
import queryClient from 'api/query'
import { useQuery } from '@tanstack/react-query'
import { getAllPaymentRequests } from 'paymentRequests/api'

const PaymentOptionWrapper = styled(Flex)`
    width: 330px;
    background-color: ${({ bgColor }) => bgColor};
    border-radius: 16px;
    transition: all 0.3s ease;
    overflow: hidden;
    text-align: center;
    & > div:first-of-type {
        border-bottom: 0.8px solid black;
        padding: 30px 20px;
        margin-bottom: 20px;
        width: 100%;
        text-align: center;
    }
`

const PaymentOptionText = styled(Flex)`
    width: 100%;
    padding: 10px 0 10px 0;
`

const Spacer = styled.div`
    width: 70%;
    content: ' ';
    border-bottom: 1.7px solid rgba(0, 0, 0, 0.24);
`

const StyledButton = styled(Button)`
    margin-top: 20px;
    border-radius: 0 0 16px 16px;
`

const PaymentTermsOption = ({ paymentTermOption, color }) => {
    const navigate = useNavigate()
    const { paymentRequestId } = useParams()
    const { fee_percentage, number_of_days } = useMemo(
        () => paymentTermOption ?? {},
        [paymentTermOption]
    )
    const { data: paymentRequestData } = useQuery({
        queryKey: ['fetchPaymentRequests'],
        queryFn: getAllPaymentRequests,
    })
    const currPaymentRequest = useMemo(
        () => paymentRequestData?.data?.find(({ id }) => id === paymentRequestId) ?? {},
        [paymentRequestData, paymentRequestId]
    )
    const handleSelect = useCallback(() => {
        queryClient.setQueryData(['selectedPaymentTermsOption'], paymentTermOption.id)
        navigate('../review')
    }, [paymentTermOption, navigate])
    return (
        <PaymentOptionWrapper gap="10" m="10px 0" bgColor={color} column centerAlign>
            {/* TODO: Make the 30, default payment terms option for vendor */}
            <Text size="28px" variant="h2">
                Pay in {number_of_days + 30} Days
            </Text>
            <PaymentOptionText centered>
                <Text size="26px">{fee_percentage}% Fee</Text>
            </PaymentOptionText>
            <Spacer />
            <PaymentOptionText centered column>
                <Text variant="h3" size="24px">
                    {formatCurrency(
                        currPaymentRequest?.payment_amount
                            ? currPaymentRequest.payment_amount +
                                  (fee_percentage * currPaymentRequest.payment_amount) / 100
                            : 0
                    )}{' '}
                    Total
                </Text>
                <Text size="18px" m="8px 0 0 0" variant="p2">
                    (including fee)
                </Text>
            </PaymentOptionText>
            <Spacer />
            <PaymentOptionText centered>
                <Text size="20px">
                    Due on{' '}
                    {dayjs(currPaymentRequest?.payment_due_date)
                        .add(number_of_days, 'd')
                        .format('MM/DD/YYYY')}
                </Text>
            </PaymentOptionText>
            <StyledButton size="full" onClick={handleSelect}>
                Select Option
            </StyledButton>
        </PaymentOptionWrapper>
    )
}

PaymentTermsOption.propTypes = {
    paymentTermOption: PropTypes.object,
    color: PropTypes.string,
}

export default PaymentTermsOption
