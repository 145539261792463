import { useEffect } from 'react'

const useOnClickOutside = (ref, handler, ignoreClickOver = false, refOffset = 0) => {
    useEffect(() => {
        const listener = (event) => {
            // Do nothing if clicking ref's element or descendent elements
            const refPosition = ref.current ? ref.current.offsetLeft : Number.MAX_SAFE_INTEGER
            if (ignoreClickOver && refPosition - refOffset < event.clientX) {
                return
            }
            if (!ref.current || ref.current.contains(event.target)) {
                return
            }
            if (handler) handler(event)
        }

        document.addEventListener('mousedown', listener)
        document.addEventListener('touchstart', listener)

        return () => {
            document.removeEventListener('mousedown', listener)
            document.removeEventListener('touchstart', listener)
        }
    }, [ref, handler, ignoreClickOver, refOffset])
}

export default useOnClickOutside
