import { useCallback, useEffect } from 'react'
import styled from '@emotion/styled'
import { useForm } from 'react-hook-form'
import { useQuery, useMutation } from '@tanstack/react-query'
import Button from 'common/components/Button'
import SinglePageCard from 'common/components/SinglePageCard'
import TextInput from 'common/components/TextInput'
import Loader from 'common/components/Loader'
import { patchCurrentUser, verifyJWT } from 'user/api'
import { useNavigate, useParams } from 'react-router-dom'
import { TOKEN_STORAGE_KEY } from 'common/constants'
import queryClient from 'api/query'
import { errorToast } from 'common/toasts'

const SubmitButton = styled(Button)`
    margin-top: 22px;
`

const PasswordReset = () => {
    const { token } = useParams()
    const navigate = useNavigate()
    const verifyToken = useCallback(() => verifyJWT(token), [token])
    const { isLoading: verifying } = useQuery({
        queryKey: ['verifyToken'],
        queryFn: verifyToken,
        enabled: !!token,
        onSuccess: (data) => localStorage.setItem(TOKEN_STORAGE_KEY, data.data.access_token),
        onError: () => {
            errorToast('There was an error when verifying user.')
            navigate('../login')
        },
    })
    const { isLoading, mutate } = useMutation({
        mutationFn: patchCurrentUser,
        onSuccess: () => navigate('../login'),
    })
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm()

    const onSubmit = useCallback(
        ({ password, confirm_password }) => {
            if (password !== confirm_password) {
                setError('confirm_password', { message: 'Passwords do not match.' })
            } else {
                mutate({ password })
            }
        },
        [mutate, setError]
    )

    useEffect(() => {
        localStorage.clear()
        queryClient.setQueryData(['currentUser'], null)
    }, [])

    if (isLoading || verifying) return <Loader />

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <SinglePageCard
                logo
                cardLogo
                title="New Password"
                subHead="Enter your new password"
                width="480px"
            >
                <TextInput
                    inputRef={() => register('password', { required: true })}
                    showLabel
                    name="password"
                    type="password"
                    label="New Password"
                    errors={errors}
                />
                <TextInput
                    inputRef={() => register('confirm_password', { required: true })}
                    showLabel
                    name="confirm_password"
                    type="password"
                    label="Confirm Password"
                    errors={errors}
                />
                <SubmitButton type="submit" size="full" loading={isLoading}>
                    Save
                </SubmitButton>
            </SinglePageCard>
        </form>
    )
}

export default PasswordReset
