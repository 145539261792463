import styled from '@emotion/styled'
import { useMutation } from '@tanstack/react-query'
import queryClient from 'api/query'
import Button from 'common/components/Button'
import PasswordInput from 'common/components/PasswordInput'
import TextInput from 'common/components/TextInput'
import Tile from 'common/components/Tile'
import { errorToast } from 'common/toasts'
import { validateStrongPW } from 'common/util'
import { useForm, useWatch } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { patchCurrentUser } from 'user/api'

const SignUpForm = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 22px;
`

const BuyerSignUp = () => {
    const navigate = useNavigate()
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm()
    const passwordCheck = useWatch({
        control,
        name: 'password',
    })
    const { isLoading, mutate } = useMutation({
        mutationKey: ['patchCurrentUser'],
        mutationFn: patchCurrentUser,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['currentUser'] })
            navigate('../business-details')
        },
        onError: () => {
            errorToast('An error occurred when updating your information. Please try again.')
        },
    })

    const onSubmit = async ({ first_name, last_name, password }) => {
        mutate({ first_name, last_name, password })
    }

    return (
        <Tile
            forcedWidth={560}
            title="Welcome!"
            subtitle="You've been invited to pay a Vendor using Market. You may also qualify for extended
              payment terms. Create your account to get started."
        >
            <SignUpForm onSubmit={handleSubmit(onSubmit)}>
                <TextInput
                    label="First Name"
                    name="first_name"
                    inputRef={() =>
                        register('first_name', {
                            required: 'Please enter your first name.',
                        })
                    }
                    errors={errors}
                    showLabel
                />
                <TextInput
                    label="Last Name"
                    name="last_name"
                    inputRef={() =>
                        register('last_name', {
                            required: 'Please enter your last name.',
                        })
                    }
                    errors={errors}
                    showLabel
                />
                <PasswordInput
                    label="Password"
                    name="password"
                    inputRef={() =>
                        register('password', {
                            required: true,
                            validate: (value) => validateStrongPW(value),
                        })
                    }
                    errors={errors}
                    toggleVisibility
                    showLabel
                />
                <PasswordInput
                    label="Confirm Password"
                    name="confirm_password"
                    inputRef={() =>
                        register('confirm_password', {
                            validate: (value) =>
                                value === passwordCheck || 'Passwords do not match',
                        })
                    }
                    errors={errors}
                    toggleVisibility
                    showLabel
                />
                <Button type="submit" loading={isLoading} size="full">
                    Next
                </Button>
            </SignUpForm>
        </Tile>
    )
}

export default BuyerSignUp
