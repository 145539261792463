import { motion } from 'framer-motion'
import PropTypes from 'prop-types'

const FadeIn = ({ className, children }) => (
    <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        style={{ width: '100%', height: '100%' }}
        transition={{ ease: 'easeIn', duration: 0.15 }}
        className={className}
    >
        {children}
    </motion.div>
)

FadeIn.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
}

export default FadeIn
