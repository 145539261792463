import { useEffect, useRef, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import tooltipIcon from 'assets/svg/tooltip.svg'

const TooltipHolder = styled.span`
    position: ${({ nuggetTooltip }) => (nuggetTooltip ? 'absolute' : 'relative')};
    ${({ nuggetTooltip }) => nuggetTooltip && 'bottom: 10px;'}
    ${({ nuggetTooltip }) => nuggetTooltip && 'right: 10px;'}
    z-index: 1;
    cursor: pointer;
    height: 20px;
    margin-left: 5px;
    &:hover {
        z-index: 10;
    }
`

const TooltipIcon = styled.img`
    width: 12px;
    height: 12px;
    transition: all 0.3s ease;
    &:hover {
        opacity: 0.7;
    }
`

const TooltipText = styled(motion.div, {
    shouldForwardProp: (prop) => prop !== 'textHeight',
})`
    position: absolute;
    background-color: var(--color-light-gray);
    padding: 7px;
    border-radius: 7px;
    width: 250px;
    left: -119px;
    top: -${({ textHeight }) => textHeight + 10}px;
    text-align: center;
    font-size: 14px;
    &::after {
        position: absolute;
        bottom: -10px;
        left: 117.5px;
        content: '\\2666';
        width: 10px;
        color: var(--color-light-gray);
    }
`

const Tooltip = ({ tooltipText, nuggetTooltip = false }) => {
    const textRef = useRef(null)
    const [textHeight, setTextHeight] = useState(0)
    const [hovering, setHovering] = useState(false)
    useEffect(() => {
        setTextHeight(textRef.current?.clientHeight)
    }, [textRef, hovering])
    return (
        <TooltipHolder nuggetTooltip={nuggetTooltip}>
            <TooltipIcon
                src={tooltipIcon}
                onMouseEnter={() => setHovering(true)}
                onMouseLeave={() => setHovering(false)}
            />
            <AnimatePresence>
                {hovering && (
                    <TooltipText
                        ref={textRef}
                        textHeight={textHeight}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3, ease: 'easeOut' }}
                    >
                        {tooltipText}
                    </TooltipText>
                )}
            </AnimatePresence>
        </TooltipHolder>
    )
}

Tooltip.propTypes = {
    tooltipText: PropTypes.string,
    nuggetTooltip: PropTypes.bool,
}

export default Tooltip
