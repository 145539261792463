import { useQuery } from '@tanstack/react-query'
import Loader from 'common/components/Loader'
import { getMiddeskVerification } from 'organizations/api'
import { useCallback, useMemo } from 'react'
import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom'

const NewRequest = () => {
    const { pathname } = useLocation()
    const { paymentRequestId } = useParams()
    const { data } = useQuery({
        queryKey: ['selectedPaymentTermsOption'],
        queryFn: () => null,
        enabled: false,
    })
    const checkUnderwriting = useCallback(
        () => getMiddeskVerification(paymentRequestId),
        [paymentRequestId]
    )
    const { data: identityVerificationData, isLoading: verificationLoading } = useQuery({
        queryKey: ['fetchMiddeskVerification'],
        queryFn: checkUnderwriting,
    })
    const { underwriting_check } = useMemo(
        () => identityVerificationData?.data ?? {},
        [identityVerificationData]
    )
    if (verificationLoading) return <Loader />
    if (underwriting_check === null) {
        return pathname.includes('underwriting-check') || pathname.includes('internal-error') ? (
            <Outlet />
        ) : (
            <Navigate to="underwriting-check" />
        )
    }
    if (underwriting_check === true) {
        if (!data) {
            return pathname.includes('payment-options') ? (
                <Outlet />
            ) : (
                <Navigate to="payment-options" />
            )
        }
        return pathname.includes('review') ? <Outlet /> : <Navigate to="review" />
    }
    if (underwriting_check === false) {
        return pathname.includes('application-denied') ? (
            <Outlet />
        ) : (
            <Navigate to="application-denied" />
        )
    }
    return <Loader />
}

export default NewRequest
